import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { DialogService } from 'app/service/dialog.service';
import { ScheduleOperationManagerService } from 'app/service/schedule-operation-maganer.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import {
  Constant,
  DestinationEnum,
  DeviceTypeEnum,
  DisplayCanvasIdEnum,
  DisplaysEnum,
  ErrorEnum,
  FIELD_COMPONENT,
  FolderNameDropPDFEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  PreviewToolEnum,
  ReferencePositionTimetableColumnEnum,
  ScreenCanvasIdEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import _ from 'lodash';

import { Helper } from 'app/common/helper';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import moment from 'moment';
import { v4 as uniqueId } from 'uuid';
import { MediaFileDropped } from 'app/module/lcd-layout-editor/lcd-layout-editor.component';
import { TextArea } from 'app/model/entity/text-area';
import { PictureArea } from 'app/model/entity/picture-area';
import { Area } from 'app/model/entity/area';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Template } from 'app/model/entity/template';
import { DrawTimetableService } from 'app/service/draw-timetable.service';
import { MediaService } from 'app/service/media.service';
import { Media } from 'app/model/entity/media';
import { Image as ImageDynamicMessage } from 'app/model/entity/image';
import { Device } from 'app/model/entity/device';
import { Timetable } from 'app/model/entity/timetable';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { DynamicMessageService } from 'app/service/dynamic-message.service';

@Component({
  selector: 'app-dialog-message-operation',
  templateUrl: './dialog-message-operation.component.html',
  styleUrls: ['./dialog-message-operation.component.scss']
})
/**
 * Dialog Pick color
 */
export class DialogMessageOperationComponent implements OnInit {
  isOnlyPictureArea: any;
  isOnlyTextArea: any;
  public Helper = Helper;

  public isPlayPreview: boolean = false;
  public isViewMonitorMode: boolean = false;
  public isEnlargePreview: boolean = false;
  public dynamicMessages: DynamicMessage[];
  public oldDynamicMessages: DynamicMessage[];
  public deviceSelected: Device;
  timeDateLine: number;

  private readonly FORMAT_HOURS = 'HH:mm:ss';
  private readonly ENTER_KEY_CODE = 13;
  private readonly MAX_LENGTH_VALUE = 256;
  private readonly IMAGE_NAME_DROP_MEDIA = 'imageDynamic';
  private readonly propertiesName = {
    dynamicMessage: this.translateService.instant('dialog-message.timetable-operation.dynamic-message'),
    emergencyLinkText: this.translateService.instant('dialog-message.timetable-operation.link-text')
  };

  public readonly canvasDisplay1Id = `${ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER}${DisplayCanvasIdEnum.DISPLAY_1}`;
  public readonly canvasDisplay2Id = `${ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER}${DisplayCanvasIdEnum.DISPLAY_2}`;
  public isDisplay2: boolean;
  public buttonsPreviewDisplay1: Array<{ key: DestinationEnum; value: string }> = [];
  public buttonsPreviewDisplay2: Array<{ key: DestinationEnum; value: string }> = [];
  public templateSelectedTypeDisplay1: DestinationEnum = DestinationEnum.MAIN;
  public templateSelectedTypeDisplay2: DestinationEnum = DestinationEnum.MAIN;
  public timetableSelected: Timetable;
  linkAreasDisplay1: Area[];
  linkAreasDisplay2: Area[];
  /**
   * media files dropped operation
   */
  private mediaFilesDroppedOperation: Array<MediaFileDropped> = new Array();
  /**
   * media's id to delete
   */
  private mediaIdsToDelete: Array<Number> = new Array();
  /**
   * list file data
   */
  private filesData: any[];
  private areaSwitchingTiming: number = 0;
  public devices: any[] = [
    {
      id: 55552,
      registrationId: '48B02D3D4964',
      deviceId: 'lecip_to_luvina',
      customTag0: null,
      customTag1: null,
      customTag2: null,
      busId: '',
      dateRegistration: null,
      modelName: null,
      serialNo: null,
      isOnEmergency: false,
      comment: null,
      status: null,
      elapsedTime: null,
      deliveryDeadline: null,
      numberOfStatusUpdates: 0,
      progress: null,
      updateStatusDate: null,
      publishInfos: [],
      type: DeviceTypeEnum.STATION_DISPLAY,
      screenOrientation: 'Normal',
      timeAutoShutDown: null,
      isManuallySetOperatingSystem: false,
      screenSize: '{"width": 1920, "height": 1080}',
      timeDateLine: 18000,
      isChecked: true
    }
  ];
  public isEditDynamicMessageMode: boolean = false;

  commonObject: Common;

  constructor(
    private scheduleOperationManagerService: ScheduleOperationManagerService,
    private drawTimetableService: DrawTimetableService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private mediaService: MediaService,
    private commonService: CommonService,
    private dynamicMessageService: DynamicMessageService,

    public dialogRef: MatDialogRef<DialogMessageOperationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.commonObject = commonService.getCommonObject();
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    const checkedDevices = this.devices.filter(device => device.isChecked);

    if (checkedDevices.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.choose-devices')
        }
      });
      return;
    }
    this.dynamicMessages = [];
    this.isEditDynamicMessageMode = true;
    const currentDate = this.getCurrentDateByConditions();
    this.dynamicMessageService
      .getDynamicMessageForMultiDeviceByDeviceId(
        checkedDevices.map(device => device.id),
        currentDate
      )
      .subscribe(
        data => {
          this.dynamicMessages = this.handleDataDynamicMessage(data);
          this.handleShowColumnTextAndMedia();
          this.clearDataMediaDropToDynamicMessages();
          this.oldDynamicMessages = _.cloneDeep(this.dynamicMessages);
          if (checkedDevices.includes(this.deviceSelected)) {
            this.reDrawDynamicMessage(this.dynamicMessages);
          }
        },
        () => this.handleErrorFromApi()
      );
  }

  onSave() {}
  cancel() {
    this.dialogRef.close();
  }
  private reDrawDynamicMessage(dynamicMessages: DynamicMessage[]) {
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.DYNAMIC_MESSAGE
    ]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    // draw dynamic messages
    this.drawDynamicMessages(dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
  }

  private handleShowColumnTextAndMedia(): void {
    this.isOnlyTextArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.textArea != null);
    this.isOnlyPictureArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.pictureArea != null);
  }

  private clearDataMediaDropToDynamicMessages(): void {
    this.mediaFilesDroppedOperation = new Array();
    this.filesData = [];
    this.mediaIdsToDelete = new Array();
    this.dynamicMessages.forEach(dynamicMessage => (dynamicMessage.uuidV4Image = ''));
  }

  private handleDataDynamicMessage(dynamicMessagesData: DynamicMessage[]): DynamicMessage[] {
    return dynamicMessagesData
      .map(dynamicMessageData => {
        return this.convertDataDynamicMessageFromServer(dynamicMessageData);
      })
      .sort(
        (dv1, dv2) =>
          +dv1.device.id - +dv2.device.id || +dv1.textArea?.id - +dv2.textArea?.id || +dv1.pictureArea?.id - +dv2.pictureArea?.id
      );
  }
  private convertDataDynamicMessageFromServer(dynamicMessageData: any) {
    if (!dynamicMessageData) {
      return;
    }
    let dynamicMessage = new DynamicMessage();
    dynamicMessage.id = dynamicMessageData['id'];
    dynamicMessage.device = this.devices.find(device => device.id == dynamicMessageData['deviceId']);
    if (dynamicMessageData['textArea']) {
      dynamicMessage.textArea = Helper.convertDataTextArea(dynamicMessageData['textArea']);
      dynamicMessage.message = dynamicMessageData['message'] ?? '';
    }
    if (dynamicMessageData['pictureArea']) {
      dynamicMessage.pictureArea = Helper.convertDataPictureArea(dynamicMessageData['pictureArea']);
      dynamicMessage.media = dynamicMessageData['media'] ?? null;
    }
    return dynamicMessage;
  }

  /**
   * Get current Date by conditions
   * @returns
   */
  private getCurrentDateByConditions(): string {
    if (Helper.convertHoursToSeconds(this.getCurrentDateByTimeZone(true)) <= this.timeDateLine) {
      const currentDate = new Date(this.getCurrentDateByTimeZone(false));
      currentDate.setDate(currentDate.getDate() - 1);
      return moment(currentDate).format(Constant.FORMAT_DATE);
    }
    return this.getCurrentDateByTimeZone(false);
  }
  /**
   * Get current date by time zone
   *
   * @param isGetHours
   * @returns
   */
  private getCurrentDateByTimeZone(isGetHours: boolean): string {
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format(isGetHours ? this.FORMAT_HOURS : Constant.FORMAT_DATE);
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi() {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.an-error')
      }
    });
  }

  public changeDynamicMessageEnterKey(dynamicMessage: DynamicMessage, index: number, $event: any) {
    if (!$event) {
      $event.preventDefault();
      return;
    }
    if (this.isPlayPreview) {
      $event.preventDefault();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    // key enter
    if ($event.keyCode == this.ENTER_KEY_CODE) {
      if (dynamicMessage.message.length > this.MAX_LENGTH_VALUE) {
        this.propertiesName.dynamicMessage = this.translateService.instant('dialog-message.timetable-operation.dynamic-message');
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-message.error-title'),
              text: Helper.getErrorMessage(
                ErrorEnum.MAX_LENGTH,
                this.propertiesName.dynamicMessage,
                this.MAX_LENGTH_VALUE,
                this.translateService
              )
            },
            autoFocus: false
          },
          () => {
            document.getElementById(`dynamicMessageInput${index}`).focus();
          }
        );
        return;
      }
      dynamicMessage.isEdit = false;
    }
  }
  /**
   * Drop media from pc
   *
   * @param file
   * @param dynamicMessage
   * @param indexDynamic
   */
  public async dropMediaFromPCDynamicMessage(file: any, dynamicMessage: DynamicMessage, indexDynamic: number): Promise<void> {
    if (dynamicMessage.textArea || !this.validateActionCommon()) {
      return;
    }
    if (!Helper.isImageFile(file[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }
    // handle file drop
    let fileDrop = file[0][0];
    const mediaType = file[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-convert-file-pdf'),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
      }
    } else {
      this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
    }
  }
  /**
   * handle After Drop Media To DynamicMessages
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param dynamicMessage
   * @param file
   * @param indexDynamic
   * @returns
   */
  private async handleAfterDropMediaToDynamicMessages(
    mediaType: any,
    fileDrop: any,
    fileName: any,
    dynamicMessage: DynamicMessage,
    file: any,
    indexDynamic: number
  ): Promise<void> {
    let fileFromPC: Media = null;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      fileFromPC = await this.mediaService
        .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.DYNAMIC_MESSAGE)
        .toPromise()
        .catch(() => {
          return null;
        });

      if (fileFromPC == null) {
        return;
      }
    } else {
      let imageInfo = await this.getImageInformation(fileDrop);
      // validate unsupported file format
      if (imageInfo[Constant.ERROR_ELEMENT]) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('dialog-error.unsupported-file-format')
          }
        });
        return;
      }
    }
    // get url media to display in GUI
    if (dynamicMessage.media && !dynamicMessage.uuidV4Image) {
      this.mediaIdsToDelete.push(dynamicMessage.media.id);
    }
    let uuid = uniqueId();
    const fileRenamed = new File([fileDrop], uuid);
    let mediaFile = new MediaFileDropped(uuid, fileName);
    const index = this.mediaFilesDroppedOperation?.findIndex(data => data?.uuidV4 == dynamicMessage.uuidV4Image);
    if (index == -1) {
      this.mediaFilesDroppedOperation.push(mediaFile);
      this.filesData.push(fileRenamed);
    } else {
      this.mediaFilesDroppedOperation[index] = mediaFile;
      this.filesData[index] = fileRenamed;
    }
    dynamicMessage.uuidV4Image = uuid;
    let mediaFromPC: Media = new ImageDynamicMessage();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    dynamicMessage.media = mediaFromPC;
    dynamicMessage.isChanged = this.oldDynamicMessages[indexDynamic].media != dynamicMessage.media;
    this.drawDynamicMessages(this.dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
  }
  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Validate action common
   *
   * @returns true: monitor mode and play preview is inactive
   */
  private validateActionCommon(): boolean {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return false;
    }
    return true;
  }
  /**
   * draw dynamic messages
   *
   * @param dynamicMessages
   */
  private drawDynamicMessages(dynamicMessages: DynamicMessage[]) {
    const areasDisplay1 = this.getAllAreasDynamicMessage(DisplaysEnum.DISPLAY_1);
    this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, dynamicMessages);
    // draw area display 1
    this.drawTimetableService.clearAreas(areasDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawAreasDynamicMessageForDisplay(display1, areasDisplay1, this.canvasDisplay1Id, dynamicMessages);

    if (this.isDisplay2) {
      const areasDisplay2 = this.getAllAreasDynamicMessage(DisplaysEnum.DISPLAY_2);
      this.drawTimetableService.clearAreas(areasDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
      // draw area display 2
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawAreasDynamicMessageForDisplay(display2, areasDisplay2, this.canvasDisplay2Id, dynamicMessages);
    }
  }
  /**
   * draw areas dynamic message for display
   * @param display
   * @param areasDynamicMessage
   * @param canvasDisplayId
   * @param dynamicMessages
   */
  private drawAreasDynamicMessageForDisplay(
    display: Template,
    areasDynamicMessage: Area[],
    canvasDisplayId: string,
    dynamicMessages: DynamicMessage[]
  ): void {
    // draw layer off
    let areasDynamicMessageLayerOff = Helper.getAreasOfLayerOnOff(areasDynamicMessage, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawDynamicMessages(
      dynamicMessages,
      areasDynamicMessageLayerOff,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );

    // draw layer on
    let areasDynamicMessageLayerOn = Helper.getAreasOfLayerOnOff(areasDynamicMessage, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawDynamicMessagesOn(
      dynamicMessages,
      areasDynamicMessageLayerOn,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
  }
  /**
   * get all areas dynamic message
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasDynamicMessage(displayEnum: DisplaysEnum): Area[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas
      .filter(
        area =>
          (area.checkTypeTextArea() && (area as TextArea).linkReferenceData == LinkDataTextEnum.DYNAMIC_MESSAGE) ||
          (!area.checkTypeTextArea() && (area as PictureArea).attribute == LinkDataPictureEnum.DYNAMIC_MESSAGE)
      )
      .map(area => {
        return area;
      });
  }
  /**
   * draw areas emergency for display
   * @param display
   * @param areasEmergency
   * @param canvasDisplayId
   * @param emergencyData
   */
  private drawAreasEmergencyForDisplay(
    display: Template,
    areasEmergency: Area[],
    canvasDisplayId: string,
    emergencyData: EmergencyData
  ): void {
    // draw layer off
    let areasEmergencyLayerOff = Helper.getAreasOfLayerOnOff(areasEmergency, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawEmergency(
      emergencyData,
      areasEmergencyLayerOff,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
    let areasEmergencyLayerOn = Helper.getAreasOfLayerOnOff(areasEmergency, display, this.areaSwitchingTiming, true);
    // draw layer on
    this.drawTimetableService.drawEmergencyOn(
      emergencyData,
      areasEmergencyLayerOn,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
  }
  /**
   * edit dynamic message
   *
   * @param dynamicMessage
   */
  public editDynamicMessage(dynamicMessage: DynamicMessage) {
    if (dynamicMessage.pictureArea || !this.validateActionCommon()) {
      return;
    }
    dynamicMessage.isEdit = true;
    if (!dynamicMessage.message) {
      dynamicMessage.message = Constant.EMPTY;
    }
  }
  /**
   * change dynamic message by keyboard
   *
   * @param index
   */
  public changeDynamicMessage(index: number): void {
    if (this.isPlayPreview || this.isViewMonitorMode) {
      return;
    }
    this.dynamicMessages[index].isChanged = this.oldDynamicMessages[index].message != this.dynamicMessages[index].message;
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.DYNAMIC_MESSAGE
    ]);
    this.drawDynamicMessages(this.dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
  }

  /**
   * allow drop
   *
   * @param e
   */
  public allowDrop(e) {
    e.preventDefault();
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  text: string;
  texts: Array<string>;
}
