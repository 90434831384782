import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DailySchedule } from '../../model/entity/playlist';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-playlist',
  templateUrl: './dialog-playlist.component.html',
  styleUrls: ['./dialog-playlist.component.scss']
})
/**
 * component class for dialog edit playlist
 */
export class DialogPlaylistComponent implements OnInit {
  @ViewChild('inputName') nameRef: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogPlaylistComponent>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  /**
   * save selected playlist
   */
  savePlaylist() {
    // validate playlist name
    let playlistName = this.data.playlist.name.trim();
    if (playlistName.length < 1) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        { data: { title: 'Error', text: 'Daily schedule name cannot be empty.' } },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      return;
    }
    // validate playlist name max length
    if (playlistName.length > 48) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        { data: { title: 'Error', text: 'Daily schedule name must contain no more than 48 characters.' } },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      return;
    }
    // validate valid playlist name
    if (!playlistName.match('^[a-zA-Z0-9]{1,48}$')) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        { data: { title: 'Error', text: 'Daily schedule name is invalid.' } },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      return;
    }
    // validate color
    if (!this.data.playlist.color) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Daily schedule color cannot be empty.' } });
      return;
    }

    if (
      this.data.playlists.findIndex(
        playlistData => playlistData.id != this.data.playlist.id && playlistData.color == this.data.playlist.color
      ) != -1
    ) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Daily schedule color already exists.' } });
      return;
    }

    if (this.data.playlists.findIndex(playlist => playlist.id != this.data.playlist.id && playlist.name == playlistName) != -1) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        { data: { title: 'Error', text: 'Daily schedule name already exists.' } },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      return;
    }

    this.data.playlist.name = playlistName;
    this.dialogRef.close({ playlist: this.data.playlist });
  }

  /**
   * set focus textbox add, update daily schedule
   */
  public setFocus() {
    setTimeout(() => {
      this.nameRef?.nativeElement.focus();
    }, 0);
  }
}

export interface DialogData {
  title: string;
  playlist: DailySchedule;
  playlists: Array<DailySchedule>;
  colors: Array<{ colorCode: string; colorSelected: string; value: any }>;
}
