import { HttpClient, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Helper } from '../../common/helper';
import { Channel } from '../../model/entity/channel';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'app-dialog-publish-data-signage-channel',
  templateUrl: './dialog-publish-data-signage-channel.component.html',
  styleUrls: ['./dialog-publish-data-signage-channel.component.scss']
})
export class DialogPublishDataSignageChannelComponent implements OnInit {
  /**
   * description
   */
  description: string;
  /**
   * date from
   */
  dateFrom: Date;

  dateFromString: string = '';
  /**
   * date to
   */
  dateTo: Date;
  /**
   * old date from
   */
  oldDateFrom: Date;
  /**
   * loading publish
   */
  loading: boolean;
  /**
   * timezone of current user
   */
  timeZone = Constant.TIME_ZONE_DEFAULT;
  /**
   * setting data
   */
  setting: any;

  constructor(
    public dialogRef: MatDialogRef<DialogPublishDataSignageChannelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private dialogService: DialogService,
    private toast: ToastrService,
    private commonService: CommonService
  ) {
    let date = Helper.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
    let contentDays = this.data.channel.calendarDays.filter(contentDay => contentDay.playlist);
    let index = contentDays.findIndex(
      contentDay =>
        Helper.getDateByDay(contentDay.fullDate.getFullYear(), contentDay.fullDate.getMonth() + 1, contentDay.fullDate.getDate()) >= date
    );
    if (index != -1) {
      this.dateFrom = contentDays[index].fullDate;
      this.oldDateFrom = this.dateFrom;
    }
    this.dateTo = contentDays[contentDays.length - 1].fullDate;
    this.description = data.channel.name;
    this.loading = false;
    this.dateFromString = Helper.convertDateToString(this.dateFrom);
  }

  ngOnInit(): void {
    this.setting = this.commonService.getCommonObject().setting;
    if (this.setting) {
      this.timeZone = this.setting.timezone.name;
    }
  }

  /**
   * close dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * set start date
   * @param event event
   */
  setStartDate(event) {
    this.dateFrom = new Date(event.target.value);
  }

  /**
   * publish data
   */
  publishData() {
    // validate valid date
    let dateNow = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    if (new Date(this.dateFrom) < new Date(dateNow) || new Date(this.dateTo) < new Date(this.dateFrom)) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Invalid start date.' } });
      return;
    }

    // validate max length
    if (this.description.length > 256) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Description cannot be longer than 256 characters.' }
      });
      return;
    }

    let dataSetting = this.commonService.getInfoPublish();
    dataSetting.idChannel = this.data.channel.id;
    let GMTTimeZone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    dataSetting.validFrom = Helper.convertDateToString(this.dateFrom) + 'T00:00:00' + GMTTimeZone;
    dataSetting.validTo = Helper.convertDateToString(this.dateTo) + 'T00:00:00' + GMTTimeZone;
    dataSetting.description = this.description;

    if (!dataSetting.projectName) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a project.' } });
      return;
    }
    this.loading = true;
    const hour = `${Math.abs(this.setting.timezone.offsetHour)}`;
    const minute = `${Math.abs(this.setting.timezone.offsetMinute)}`;
    const timezone =
      this.setting.timezone.offsetHour > 0
        ? `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`
        : `-${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    const params = new HttpParams().set('timezone', timezone);
    this.http
      .post(Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL + 'api/publish/dsc', dataSetting, {
        params,
        reportProgress: true,
        observe: 'events'
      })
      .subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              console.log('Request succeeded!');
              this.toast.success('Publish successfully.', '');
              this.closeDialog();
          }
        },
        error => {
          this.dialogRef.close();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
  }
}

export interface DialogData {
  channel: Channel;
}
