import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectFitEnum } from 'app/config/constants';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { ExternalContent } from 'app/model/entity/external-content';
import { DialogService } from 'app/service/dialog.service';
import { ExternalContentService } from 'app/service/external-content-service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'dialog-setting-external-content',
  templateUrl: './dialog-setting-external-content.component.html',
  styleUrls: ['./dialog-setting-external-content.component.scss']
})
export class DialogSettingExternalContentComponent implements OnInit {
  externalContents: ExternalContent[];
  dataExternalSettings: DataExternalSetting[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataExternalSettingsOfTemplate: any,
    private externalContentService: ExternalContentService,
    public dialogRef: MatDialogRef<DialogSettingExternalContentComponent>,
    private dialogService: DialogService,
    private pictureAreaService: PictureAreaService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.dataExternalSettings = _.cloneDeep(this.dataExternalSettingsOfTemplate);
    this.externalContentService.getAllExternalContent().subscribe(externalContents => {
      this.externalContents = Helper.convertDataExternalContents(externalContents);
    });
  }

  /**
   * get list data different size with image
   * @param dataExternalSettings
   * @param externalContents
   * @returns list data different size with image
   */
  private getDataExternalSettingsDifferentSize(
    dataExternalSettings: DataExternalSetting[],
    externalContents: ExternalContent[]
  ): Array<DataExternalSetting> {
    let dataExternalSettingsDifferentSize = new Array<DataExternalSetting>();
    dataExternalSettings.forEach(dataExternalSetting => {
      let externalContentSelected = externalContents.find(external => external.id == dataExternalSetting.idExternalContent);
      // compare
      if (externalContentSelected && !this.compareTwoSize(dataExternalSetting, externalContentSelected)) {
        dataExternalSettingsDifferentSize.push(dataExternalSetting);
      }
    });
    return dataExternalSettingsDifferentSize;
  }

  /**
   * compare size area and size image external content
   * @param dataExternalSetting
   * @param externalContentSelected
   * @returns true if equals size
   */
  private compareTwoSize(dataExternalSetting: DataExternalSetting, externalContentSelected: ExternalContent): boolean {
    return (
      dataExternalSetting['width'] == externalContentSelected['width'] && dataExternalSetting['height'] == externalContentSelected['height']
    );
  }

  /**
   * send data back to save
   */
  saveDataExternal() {
    // get list dataExternalSetting choose
    let dataResults = this.dataExternalSettings.filter(data => data.idExternalContent != -1);
    // get data different size with image
    let dataExternalSettingsDifferentSize = this.getDataExternalSettingsDifferentSize(dataResults, this.externalContents);
    // if all is same size with image
    if (dataExternalSettingsDifferentSize.length == 0) {
      this.dialogRef.close(this.dataExternalSettings);
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('dialog-setting-external-content.area-different-size'),
            button1: this.translateService.instant('dialog-setting-external-content.yes'),
            button2: this.translateService.instant('dialog-setting-external-content.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          // update area to fill
          let idsArea = dataExternalSettingsDifferentSize.filter(data => data['objectFit'] != ObjectFitEnum.FILL).map(data => data.idArea);
          if (idsArea.length) {
            this.pictureAreaService.updateObjectFitToFill(idsArea).toPromise();
          }
          // send back data to save
          this.dialogRef.close(this.dataExternalSettings);
        }
      );
    }
  }
}
