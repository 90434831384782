import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { ImageCropperComponent } from 'app/public_api';
import { DialogService } from 'app/service/dialog.service';
import { MediaService } from 'app/service/media.service';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { CropperSettings } from './../../lib/image-cropper/cropper-settings';
import { CropPosition } from './../../lib/image-cropper/model/cropPosition';
@Component({
  selector: 'dialog-triming',
  templateUrl: './dialog-triming.component.html',
  styleUrls: ['./dialog-triming.component.scss']
})
export class DialogTrimingComponent implements OnInit, OnDestroy {
  /**
   * Constant's component
   */
  private readonly IMAGE_NAME_TS = 'spot';
  private readonly IMAGE_NAME_TS_TRIMING = 'spot_circle';
  // Cropper 2 data
  public data2: any;
  public cropperSettings2: CropperSettings;
  isConfirm: boolean = false;
  /**
   *fileData Image
   */
  fileDataImage: any;
  /**
   * file Data Image Cicle
   */
  fileDataImageCicle: any; // file Data Image Cicle
  mediaResult: any[] = []; // media result
  isEdit: boolean = false; //isEdit
  fileDownload: any; // fileDownload
  cropPosition: CropPosition;
  @ViewChild('customImage') customImageRef: ElementRef;
  @ViewChild('cropper2', { static: true })
  public cropper2: ImageCropperComponent;

  public onChange: ($event: any) => void;
  public resetCroppers: () => any;

  ngOnInit(): void {
    if (this.data && !this.data.isEdit) {
      this.onChange(this.data.dataEvent);
    } else {
      this.isEdit = true;
      this.mediaService.getFileFromUrl(this.data?.dataURL?.originalFileURL).subscribe(
        res => {
          const file = new File([res.body], 'image.png', {
            type: 'image/jpeg'
          });
          this.fileDownload = file;
          this.onChange(this.fileDownload);
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('ticket-editor.common-error')
            }
          });
          this.dialogRef.close();
        }
      );
    }

    if (this.data?.cropPosition) {
      this.cropPosition = _.cloneDeep(this.data.cropPosition);
    }
  }

  ngOnDestroy(): void {
    this.fileDownload = undefined;
    this.fileDataImage = undefined;
    this.fileDataImageCicle = undefined;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTabSpot,
    private dialogRef: MatDialogRef<DialogTrimingComponent>,
    private mediaService: MediaService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    // Cropper settings 2
    this.cropperSettings2 = new CropperSettings();
    this.cropperSettings2.width = 200;
    this.cropperSettings2.height = 200;
    this.cropperSettings2.keepAspect = false;

    this.cropperSettings2.croppedWidth = 600;
    this.cropperSettings2.croppedHeight = 600;

    this.cropperSettings2.canvasWidth = 500;
    this.cropperSettings2.canvasHeight = 300;

    this.cropperSettings2.minWidth = 100;
    this.cropperSettings2.minHeight = 100;

    this.cropperSettings2.rounded = true;
    this.cropperSettings2.minWithRelativeToResolution = false;

    this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings2.noFileInput = true;
    this.cropperSettings2.fileType = 'image/jpeg';

    this.data2 = {};

    this.onChange = (fileData: any) => {
      const image: any = new Image();
      const file: File = fileData;
      const myReader: FileReader = new FileReader();
      myReader.addEventListener('loadend', (loadEvent: any) => {
        image.src = loadEvent.target.result;
        this.cropper2.setImage(image);
      });

      myReader.readAsDataURL(file);
    };

    this.resetCroppers = () => {
      this.cropper2.reset();
    };
  }

  /**
   * confirmConversion
   */
  confirmConversion(): void {
    this.isConfirm = !this.isConfirm;
  }

  /**
   * cancelConfirmTriming
   */
  cancelConfirmTriming(): void {
    this.isConfirm = !this.isConfirm;
  }

  /**
   * uploadImage
   */
  uploadImage() {
    let fileUpoad;
    let mediaName;
    let mediaType;
    if (!this.isEdit) {
      // get the original file
      fileUpoad = this.data?.dataEvent;
      mediaName = fileUpoad?.name;
      mediaType = mediaName.slice(mediaName.lastIndexOf('.') + 1, mediaName.length).toLowerCase();
    } else {
      fileUpoad = this.fileDownload;
      const fileNameOriginal = this.getFileNameFromUrl(this.data?.dataURL.originalFileURL);
      mediaType = fileNameOriginal.slice(fileNameOriginal.lastIndexOf('.') + 1, fileNameOriginal.length).toLowerCase();
      mediaName = `${fileNameOriginal.split('_')[0]}.${mediaType}`;
    }
    mediaName = mediaName.replace(/_spot(?=\.[^.]*$)/, ''); // In case the file name contains the character _spot at the end of the file name
    const fileName = `${mediaName.split('.').shift()}_${this.IMAGE_NAME_TS}.${mediaType}`;
    this.fileDataImage = new File([fileUpoad], fileName, { type: fileUpoad?.type });
    // Get the cropped image file
    this.processCustomImage(mediaName)
      .then(fileDataImageCicle => {
        this.fileDataImageCicle = fileDataImageCicle;
        this.mediaResult?.push(this.fileDataImage);
        this.mediaResult?.push(this.fileDataImageCicle);
        this.mediaResult?.push(this.cropPosition);
        this.dialogRef.close(this.mediaResult);
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * processCustomImage
   * @param fileName
   * @returns
   */
  processCustomImage(fileName: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const customImageElement: HTMLImageElement = this.customImageRef.nativeElement;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Set canvas size to match the cropped dimensions of the image
      canvas.width = 600;
      canvas.height = 600;

      // Draw the image onto the canvas with border-radius
      context.save();
      context.beginPath();
      context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI);
      context.closePath();
      context.clip();
      context.drawImage(customImageElement, 0, 0, canvas.width, canvas.height);
      context.restore();

      // Get the image data from the canvas as a blob
      canvas.toBlob(blob => {
        if (blob) {
          const fileNameTriming = `${fileName.split('.').shift()}_${this.IMAGE_NAME_TS_TRIMING}.${Constant.FORMAT_TYPE_PNG}`;
          const file = new File([blob], fileNameTriming, { type: Constant.FORMAT_TYPE_IMAGE_PNG });
          resolve(file);
        } else {
          reject(new Error('Failed to create PNG file.'));
        }
      }, Constant.FORMAT_TYPE_IMAGE_PNG);
    });
  }

  /**
   * getFileFromUrl
   * @param url
   * @returns
   */
  getFileFromUrl(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = 'image.png';
          saveAs(blob, fileName);
          resolve();
        })
        .catch(error => {
          console.error('Error fetching file:', error);
          reject(error);
        });
    });
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  getFileNameFromUrl(url: string): string {
    const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    return filename;
  }

  /**
   * cancelTriming
   */
  cancelTriming(): void {
    this.dialogRef.close();
  }

  getcropPositionChange(e: any) {
    this.cropPosition = e;
  }
}
export interface DialogDataTabSpot {
  dataEvent: any;
  isEdit?: boolean;
  dataURL?: DataURL;
  cropPosition?: CropPosition;
}

export interface DataURL {
  originalFileURL: string;
  editedFileURL: string;
}
