import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
@Component({
  selector: 'dialog-setting-auto-update',
  templateUrl: './dialog-setting-auto-update.component.html',
  styleUrls: ['./dialog-setting-auto-update.component.scss']
})
export class DialogSettingAutoUpdateComponent implements OnInit {
  /**
   * is auto update
   */
  public isAutoUpdate: boolean;

  contentSelected: any;

  constructor(public dialogRef: MatDialogRef<DialogSettingAutoUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.isAutoUpdate = this.data.content.isAutoUpdate;
    this.contentSelected = _.cloneDeep(this.data.content);
  }

  /**
   * toggle change auto update
   *
   * @returns
   */
  public toggleChangeSimpleSyncSetting(): void {
    this.isAutoUpdate = !this.isAutoUpdate;
  }

  /**
   * save simple sync setting
   *
   * @returns
   */
  public save(): void {
    this.contentSelected.isAutoUpdate = this.isAutoUpdate;
    this.dialogRef.close(this.contentSelected);
  }
}
export interface DialogData {
  content: any;
}
