import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from 'app/config/constants';
import { ErrorMessage } from 'app/config/error-message';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { User } from '../../core/user/user.model';
import { Project } from '../../model/entity/project';
import { ProjectService } from '../../service/project.service';
import { UserService } from '../../service/user.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'app-dialog-project-manager',
  templateUrl: './dialog-project-manager.component.html',
  styleUrls: ['./dialog-project-manager.component.scss']
})
export class DialogProjectManagerComponent implements OnInit {
  readonly NAME_VALUE = 'name';
  readonly DESCRIPTION_VALUE = 'description';
  readonly DESTINATION_VALUE = 'destination';
  readonly ON_BUS_VALUE = 'on-bus';
  readonly SIGNAGE_CHANNEL_VALUE = 'signage-channel';
  readonly STATION_VALUE = 'station';
  readonly SIGNAGE_DISPLAY_VALUE = 'signage-display';
  @ViewChild('inputName', { static: false }) inputNameRef?: ElementRef;
  /**
   * List User
   */
  users: Array<User> = new Array<User>();
  /**
   * FormGroup formValidate
   */
  formValidate: FormGroup;
  /**
   * true if submit
   */
  submitted = false;
  /**
   * true if not checked all checkbox, false if checked checkbox min(1)
   */
  isErrorRequiredCheckbox = false;
  /**
   * true if checked Destination Sign
   */
  isChecked: boolean = false;
  /**
   * true if checked On-bus Display
   */
  isChecked1: boolean = false;
  /**
   * true if checked Digital Signage Channel
   */
  isChecked2: boolean = false;
  /**
   * true if checked Station Display
   */
  isChecked3: boolean = false;
  /**
   * true if checked Signage Display
   */
  isChecked4: boolean = false;
  /**
   * List member of project
   */
  selectedProjectMembers: Array<User>;
  /**
   * true if project name is not be taken
   */
  isNotValidProjectName: boolean = false;
  /**
   * list project
   */
  projects: Array<Project> = new Array<Project>();
  /**
   * user clone
   */
  usersClone: Array<User>;

  /**
   * constructor
   * @param dialogRef reference to an opened dialog
   * @param data dialog data
   * @param userService Object Userservice
   * @param formBuilder FormBuilder
   */
  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogProjectManagerComponent>,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.data.projects) {
      this.projects = this.data.projects;
    }
    if (!this.data.project.id) {
      this.selectedProjectMembers = [];
    } else {
      this.selectedProjectMembers = new Array<User>();
      if (this.data.project.isPublic) {
        this.data.project.isPublic = true;
      } else {
        this.projectService.getProjectMembers(this.data.project.id as number).subscribe(
          members => {
            this.selectedProjectMembers = members.filter(projectMember => projectMember.id != this.userIdLocal);
          },
          error => {
            this.dialogRef.close();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            return;
          }
        );
      }
    }
    this.getListUser();
    this.validateForm();
  }

  /**
   * Validate form
   */
  validateForm() {
    this.formValidate = this.formBuilder.group({
      name: [this.data.project.name, [Validators.required, Validators.maxLength(64)]],
      description: [this.data.project.description, [Validators.maxLength(256)]],
      destination: [this.data.project.hasEds],
      busInfo: [this.data.project.hasBid],
      signage: [this.data.project.hasDsc],
      station: [this.data.project.hasBsd],
      signageDisplay: [this.data.project.hasSd]
    });
  }

  get availableUsers(): Array<User> {
    return this.usersClone.filter(user => !this.selectedProjectMembers.some(u => u.id == user.id) && user.userId != Constant.ROOT);
  }

  get userNameLocal(): string {
    return this.commonService.getCommonObject().userName;
  }

  get userIdLocal(): number {
    return this.commonService.getCommonObject().userId;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formValidate.controls;
  }

  /**
   * Update text box, check box
   * @param data data
   * @param propertyName property name
   */
  public updateTextBox(data: any, propertyName: String) {
    let inputValue = data.target.value.trim();
    let inputChecked = data.target.checked;
    switch (propertyName) {
      case this.NAME_VALUE:
        this.isNotValidProjectName = false;
        this.data.project.name = inputValue == '' ? '' : inputValue;
        break;
      case this.DESCRIPTION_VALUE:
        this.data.project.description = inputValue == '' ? '' : inputValue;
        break;
      case this.DESTINATION_VALUE:
        this.data.project.hasEds = inputChecked;
        break;
      case this.ON_BUS_VALUE:
        this.data.project.hasBid = inputChecked;
        break;
      case this.SIGNAGE_CHANNEL_VALUE:
        this.data.project.hasDsc = inputChecked;
        break;
      case this.STATION_VALUE:
        this.data.project.hasBsd = inputChecked;
        break;
      case this.SIGNAGE_DISPLAY_VALUE:
        this.data.project.hasSd = inputChecked;
        break;
      default:
        break;
    }
  }

  /**
   * change project member
   * @param userId user ID
   * @param index index in user list
   */
  changeProjectMember(userId, index?: number) {
    if (!+userId) {
      this.data.project.isPublic = true;
      this.selectedProjectMembers = [];
      return;
    }
    this.data.project.isPublic = false;
    var user = this.usersClone.find(user => user.id == userId);
    this.selectedProjectMembers[index] = user;
  }
  /**
   * Add project member
   */
  addMember() {
    if (this.selectedProjectMembers.length == 9 || this.data.project.isPublic) {
      return;
    }
    this.selectedProjectMembers.push(new User());
  }

  /**
   * Remove project member
   * @param index index in project member
   */
  removeMember(index) {
    this.selectedProjectMembers.splice(index, 1);
  }

  /**
   * Get list User to display in select box
   */
  getListUser() {
    this.userService.getUsers().subscribe(
      usersData => {
        this.users = usersData;
        this.usersClone = _.cloneDeep(this.users).filter(user => user.id != this.userIdLocal);
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }
  /**
   * Handle button save
   */
  async save() {
    this.validateForm();
    const { value, valid } = this.formValidate;
    this.submitted = true;
    if (!value.destination && !value.busInfo && !value.signage && !value.station && !value.signageDisplay) {
      this.isErrorRequiredCheckbox = true;
      return;
    } else {
      this.isErrorRequiredCheckbox = false;
    }
    if (valid) {
      let project = this.data.project.id ? this.data.project : new Project();
      let creationTime = '';
      const user = this.users.find(user => user.id == this.userIdLocal);
      const selectedProjectMembersClone = project.isPublic
        ? new Array<User>()
        : [...this.selectedProjectMembers.filter(projectMember => projectMember.id), user];
      creationTime = this.data.project.id
        ? this.data.project.creationTime
        : formatDate(new Date(), Constant.FORMAT_DATE_TIME, Constant.LOCALE_CODE);
      // Set data
      project.name = value.name;
      project.creationTime = creationTime;
      project.description = value.description;
      project.hasEds = value.destination;
      project.hasBid = value.busInfo;
      project.hasDsc = value.signage;
      project.hasBsd = value.station;
      project.hasSd = value.signageDisplay;
      project.isPublic = this.data.project.isPublic;
      project.projectMembers = selectedProjectMembersClone?.filter(projectMember => projectMember != null);
      if (!project.id) {
        this.projectService.addProject(project).subscribe(
          data => {
            this.dialogRef.close(data);
          },
          error => {
            if (error.error?.errorKey == ErrorMessage.NAME_EXIST) {
              this.isNotValidProjectName = true;
              this.inputNameRef?.nativeElement.focus();
              return;
            }
            this.dialogRef.close();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      } else {
        this.projectService.editProject(project).subscribe(
          data => {
            this.dialogRef.close(data);
          },
          error => {
            if (error.error?.errorKey == ErrorMessage.NAME_EXIST) {
              this.isNotValidProjectName = true;
              this.inputNameRef?.nativeElement.focus();
              return;
            }
            this.dialogRef.close();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      }
    }
  }
}

export interface DialogData {
  project: Project;
  projects: Array<Project>;
}
