import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from 'app/config/constants';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { ValidationFormService } from 'app/service/validation-form.service';

@Component({
  selector: 'dialog-template-led',
  templateUrl: './dialog-template-led.component.html',
  styleUrls: ['./dialog-template-led.component.scss']
})
export class DialogTemplateLedComponent implements OnInit {
  Constant = Constant;
  templateLedForm: FormGroup;
  isSubmitted: boolean = false;
  propertyName: { [key: string]: string } = {
    name: 'Template name'
  };
  /**
   * templates of group
   */
  templatesOfGroup: Array<TemplateLED>;

  constructor(
    public dialogRef: MatDialogRef<DialogTemplateLedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService
  ) {}

  ngOnInit(): void {
    this.templatesOfGroup = this.data?.templatesOfGroup ?? new Array<TemplateLED>();
    this.templateLedForm = this.formBuilder.group({
      name: [
        this.data.template.name,
        [
          this.validationFormService.invalidEmpty,
          this.validationFormService.maxLength(Constant.MAX_LENGTH_TEMPLATE_NAME_LED),
          this.validationFormService.existTemplateName(this.templatesOfGroup)
        ]
      ]
    });
  }

  /**
   * save template
   */
  public save(): void {
    this.isSubmitted = true;
    if (this.templateLedForm.valid) {
      this.data.template.name = this.templateLedForm.value.name.trim();
      this.dialogRef.close(this.data.template);
    }
  }
}
export interface DialogData {
  title: string;
  template: TemplateLED;
  templatesOfGroup: Array<TemplateLED>;
}
