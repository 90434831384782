import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, TemplateModeEnum } from 'app/config/constants';
import { TemplateGroup } from '../../model/entity/template-group';
import { ValidationFormService } from '../../service/validation-form.service';
import { TemplateGroupService } from '../../service/template-group.service';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'app-dialog-template-group-detail',
  templateUrl: './dialog-template-group-detail.component.html',
  styleUrls: ['./dialog-template-group-detail.component.scss']
})
export class DialogTemplateGroupDetailComponent implements OnInit {
  Constant = Constant;
  templateGroupDetailForm: FormGroup;
  submitted: boolean = false;
  TemplateModeEnum = TemplateModeEnum;
  propertyName: { [key: string]: string } = {
    name: 'Template group name',
    width: 'Template group width',
    height: 'Template group height'
  };

  /**
   * placeholder name
   */
  placeholderName: string;
  /**
   * template modes used
   */
  public templateModesUsed: Array<TemplateModeEnum>;
  // true if duplicate name
  public isDuplicate: boolean = false;
  // constant
  private readonly NUMBER_TEMPLATE_GROUP_MAX = 100;
  constructor(
    public dialogRef: MatDialogRef<DialogTemplateGroupDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService,
    private templateGroupService: TemplateGroupService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.templateModesUsed = this.data.templateModesUsed;
    this.placeholderName = this.translateService.instant('dialog-template-group-detail.placeholder-name');
    this.createForm();
  }

  private createForm() {
    this.templateGroupDetailForm = this.formBuilder.group({
      name: [
        this.data.templateGroup.name,
        [
          this.validationFormService.invalidEmpty,
          this.validationFormService.maxLength(Constant.MAX_LENGTH_TEMPLATE_GROUP_NAME),
          this.validationFormService.existTemplateName(this.data.templateGroups)
        ]
      ],
      width: [
        this.data.templateGroup.width,
        [this.validationFormService.invalidEmpty, Validators.min(Constant.MIN_TEMPLATE_SIZE), Validators.max(Constant.MAX_TEMPLATE_SIZE)]
      ],
      height: [
        this.data.templateGroup.height,
        [this.validationFormService.invalidEmpty, Validators.min(Constant.MIN_TEMPLATE_SIZE), Validators.max(Constant.MAX_TEMPLATE_SIZE)]
      ],
      templateMode: [this.data.templateGroup.templateMode, [Validators.required]]
    });
  }

  public save() {
    this.data.templateGroup.templateMode = this.templateGroupDetailForm.value.templateMode;
    this.data.templateGroup.name = this.templateGroupDetailForm.value.name;
    this.data.templateGroup.width = this.templateGroupDetailForm.value.width;
    this.data.templateGroup.height = this.templateGroupDetailForm.value.height;
    this.createForm();
    this.submitted = true;
    if (this.templateGroupDetailForm.valid) {
      var creationTime = '';
      // If add template group then set creation time for template group
      if (!this.data.templateGroup.id) {
        creationTime = formatDate(new Date(), Constant.FORMAT_DATE_TIME, Constant.LOCALE_CODE);
      } else {
        creationTime = this.data.templateGroup.creationTime;
      }
      let templateGroupNew = new TemplateGroup(
        this.templateGroupDetailForm.value.name,
        this.templateGroupDetailForm.value.width,
        this.templateGroupDetailForm.value.height,
        creationTime,
        this.templateGroupDetailForm.value.templateMode
      );
      if (this.data.templateGroup.id) {
        templateGroupNew.width = this.data.templateGroup.width;
        templateGroupNew.height = this.data.templateGroup.height;
        templateGroupNew.templateMode = this.data.templateGroup.templateMode;
        templateGroupNew.id = this.data.templateGroup.id;
        this.templateGroupService.updateTemplateGroup(templateGroupNew).subscribe(
          templateGroupEdit => {
            if (templateGroupEdit) {
              this.dialogRef.close(templateGroupEdit);
            }
          },
          error => {
            this.showDialogError(error);
          }
        );
      } else {
        this.templateGroupService.addTemplateGroup(templateGroupNew).subscribe(
          (templateGroupAdd: TemplateGroup) => {
            if (templateGroupAdd) {
              this.dialogRef.close(templateGroupAdd);
            }
          },
          error => {
            this.showDialogError(error);
          }
        );
      }
    }
  }
  /**
   * handle Error Save Timetable
   */
  private showDialogError(error: any): void {
    let msg = this.translateService.instant('lcd-layout-editor.msg.common-error');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = Helper.formatString(
        this.translateService.instant('lcd-layout-editor.msg.template-group-max'),
        `${this.NUMBER_TEMPLATE_GROUP_MAX}`
      );
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
      this.isDuplicate = true;
      return;
    } else if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-error.error-network-api');
    }
    this.isDuplicate = false;
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
        text: msg
      }
    });
  }
  /**
   * handling Decimal
   */
  handlingDecimal(inputNumber: number, input: any) {
    input.value = Helper.handlingDecimal(inputNumber);
  }
}

export interface DialogData {
  title: string;
  templateGroup: TemplateGroup;
  templateGroups: Array<TemplateGroup>;
  templateModesUsed?: Array<TemplateModeEnum>;
}
