import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { SettingDeviceAutoRefresh } from 'app/model/entity/setting-device-auto-refresh';
import { DialogService } from 'app/service/dialog.service';
import { SettingDeviceAutoRefreshService } from 'app/service/setting-device-auto-refresh.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-setting-device-monitor-auto-refresh',
  templateUrl: './dialog-setting-device-monitor-auto-refresh.component.html',
  styleUrls: ['./dialog-setting-device-monitor-auto-refresh.component.scss']
})
export class DialogSettingDeviceMonitorAutoRefreshComponent implements OnInit {
  /**
   * constants auto refresh
   */
  public readonly MIN_TIME_AUTO_FRESH_IN_SECOND = 3;
  public readonly MAX_TIME_AUTO_FRESH_IN_SECOND = 60;
  public readonly DEFAULT_TIME_AUTO_FRESH_IN_SECOND = 10;

  /**
   * error time auto refresh
   */
  errorTimeAutoRefresh: string;
  /**
   * settingDeviceMonitorAutoRefresh
   */
  settingDeviceMonitorAutoRefresh: SettingDeviceAutoRefresh;

  constructor(
    public dialogRef: MatDialogRef<DialogSettingDeviceMonitorAutoRefreshComponent>,
    private settingDeviceAutoRefreshService: SettingDeviceAutoRefreshService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.settingDeviceMonitorAutoRefresh = this.data.settingDeviceMonitorAutoRefresh ?? new SettingDeviceAutoRefresh(false, 0);
  }

  /**
   * save information setting device auto refresh
   */
  private saveInformationSettingAutoRefresh(): void {
    this.settingDeviceAutoRefreshService.saveDataSetting(this.settingDeviceMonitorAutoRefresh).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.dialogRef.close(data);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * validate character input number
   * @param event
   */
  public validateCharacterNumber(event): void {
    if (Constant.LIST_ABORT_TYPE_NUMBER.includes(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * toggle auto refresh
   */
  public toggleAutoRefresh(): void {
    this.settingDeviceMonitorAutoRefresh.autoRefresh = !this.settingDeviceMonitorAutoRefresh.autoRefresh;
    if (this.settingDeviceMonitorAutoRefresh.autoRefresh) {
      this.settingDeviceMonitorAutoRefresh.timeAutoRefresh = this.DEFAULT_TIME_AUTO_FRESH_IN_SECOND;
    }
  }

  /**
   * validate information setting
   */
  private validateInformationSetting(): boolean {
    if (!this.settingDeviceMonitorAutoRefresh.autoRefresh) {
      return true;
    }
    // validate time auto refresh
    if (this.settingDeviceMonitorAutoRefresh.timeAutoRefresh == null) {
      this.errorTimeAutoRefresh = this.translateService.instant('dialog-setting-time-dashboard.message.time-empty');
    } else if (this.settingDeviceMonitorAutoRefresh.timeAutoRefresh < this.MIN_TIME_AUTO_FRESH_IN_SECOND) {
      this.errorTimeAutoRefresh = this.translateService.instant('dialog-setting-time-dashboard.message.time-between');
    } else if (this.settingDeviceMonitorAutoRefresh.timeAutoRefresh > this.MAX_TIME_AUTO_FRESH_IN_SECOND) {
      this.errorTimeAutoRefresh = this.translateService.instant('dialog-setting-time-dashboard.message.time-between');
    } else {
      this.errorTimeAutoRefresh = null;
    }
    return !this.errorTimeAutoRefresh;
  }

  /**
   * save data setting
   */
  public saveInformationSetting(): void {
    // validate information
    if (!this.validateInformationSetting()) {
      return;
    }
    // save information
    this.saveInformationSettingAutoRefresh();
  }

  /**
   * checkDecimal
   */
  public handlingDecimal(element: any): void {
    element.value = Helper.handlingDecimal(element.value);
  }
}

export interface DialogData {
  settingDeviceMonitorAutoRefresh: SettingDeviceAutoRefresh;
}
