import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { BusStop } from 'app/model/entity/bus-stop';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-publish-data-station',
  templateUrl: './dialog-publish-data-station.component.html',
  styleUrls: ['./dialog-publish-data-station.component.scss']
})
export class DialogPublishDataStationComponent implements OnInit {
  /**
   * title
   */
  title: string = 'Publish';
  /**
   * description
   */
  description: string = '';
  /**
   * date from
   */
  dateFrom: Date;
  /**
   * time zone
   */
  timeZone = Constant.TIME_ZONE_DEFAULT;
  /**
   * loading publish
   */
  loading: boolean;
  /**
   * setting data
   */
  setting: any;
  /**
   * bus stop list
   */
  busStops: BusStop[];

  constructor(
    public dialogRef: MatDialogRef<DialogPublishDataStationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private toast: ToastrService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) {
    this.dateFrom = new Date();
    this.description = moment(this.dateFrom).format('MMM. DD, YYYY');
    this.busStops = data.busStopSelecteds;
    this.loading = false;
  }

  ngOnInit(): void {
    this.setting = this.commonService.getCommonObject().setting;
    if (this.setting) {
      this.timeZone = this.setting.timezone.name;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * set start date
   * @param event event
   */
  setStartDate(event) {
    this.dateFrom = new Date(event.target.value);
  }

  publish() {
    if (this.description.length > 256) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Description must contain no more than 256 characters.' }
      });
      return;
    }

    let dataSetting = this.commonService.getInfoPublish();
    dataSetting.busStopsId = this.busStops.map(busStop => busStop.id);
    let GMTTimeZone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    dataSetting.validFrom = Helper.convertDateToString(this.dateFrom) + 'T00:00:00' + GMTTimeZone;
    dataSetting.description = this.description;
    this.loading = true;
    this.http
      .post(Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL + 'api/publish/sd', dataSetting, {
        reportProgress: true,
        observe: 'events'
      })
      .subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              console.log('Request succeeded!');
              this.loading = false;
              setTimeout(() => {
                this.toast.success('Publish successfully.', '');
                this.closeDialog();
              }, 1000);
          }
        },
        error => {
          this.dialogRef.close();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
  }
}

export interface DialogData {
  busStopSelecteds: Array<BusStop>;
}
