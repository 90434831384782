import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, DestinationEnum, ModeActionTemplate, TemplateModeEnum, TemplateTypeEnum } from 'app/config/constants';
import { TemplateGroup } from 'app/model/entity/template-group';
import { DialogService } from 'app/service/dialog.service';
import { TemplateService } from 'app/service/template.service';
import { Template } from '../../model/entity/template';
import { ValidationFormService } from '../../service/validation-form.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-template-detail',
  templateUrl: './dialog-template-detail.component.html',
  styleUrls: ['./dialog-template-detail.component.scss']
})
export class DialogTemplateDetailComponent implements OnInit {
  Constant = Constant;
  TemplateTypeEnum = TemplateTypeEnum;
  DestinationEnum = DestinationEnum;
  TemplateModeEnum = TemplateModeEnum;
  templateDetailForm: FormGroup;
  submitted: boolean = false;
  propertyName: { [key: string]: string } = {
    name: 'Template name',
    transitionTime: 'Transition time'
  };
  readonly DEFAULT_TRANSITION_TIME = 10;
  readonly DEFAULT_PAGE_ROW_NUMBER = 10;
  /**
   * placeholder name
   */
  public placeholderName: string;

  // true if duplicate name
  public isDuplicate: boolean;
  // constant
  private readonly NUMBER_TEMPLATE_MAX = 100;

  ModeActionTemplate = ModeActionTemplate;

  constructor(
    public dialogRef: MatDialogRef<DialogTemplateDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    this.placeholderName = this.translateService.instant('dialog-template-detail.placeholder-name');
    this.templateDetailForm = this.formBuilder.group({
      name: [
        this.data.template.name,
        [
          this.validationFormService.invalidEmpty,
          this.validationFormService.maxLength(Constant.MAX_LENGTH_TEMPLATE_NAME_LCD),
          this.validationFormService.existTemplateName(this.data.templatesOfGroup)
        ]
      ],
      transitionTime: [
        this.data.template.transitionTime,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_TIME_AUTO_TRANSITION),
          Validators.max(Constant.MAX_TIME_AUTO_TRANSITION)
        ]
      ],
      pageRowNumber: [
        this.data.template.pageRowNumber,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_ROW_PAGE_SWITCHING),
          Validators.max(Constant.MAX_ROW_PAGE_SWITCHING)
        ]
      ]
    });
    this.changeDetectorRef.detectChanges();
  }

  /**
   * toggle auto transition
   */
  toggleAutoTransition(): void {
    this.data.template.isAutoTransition = !this.data.template.isAutoTransition;
    if (this.data.template.isAutoTransition) {
      this.templateDetailForm.get('transitionTime').enable();
      if (!this.data.template.transitionTime) {
        this.data.template.transitionTime = this.DEFAULT_TRANSITION_TIME;
        this.changeTemplateType();
      }
    } else {
      this.templateDetailForm.get('transitionTime').disable();
    }
    if (this.data.template.isAutoTransition && this.data.template.isPageSwitching) {
      this.data.template.isPageSwitching = false;
    }
  }

  /**
   * change template type
   */
  changeTemplateType(): void {
    this.data.template.destination =
      this.data.template.templateType == TemplateTypeEnum.MAIN ? DestinationEnum.SUB_PAGE_1 : DestinationEnum.MAIN;
    if (this.data.template.templateType == TemplateTypeEnum.EMERGENCY) {
      this.data.template.isAutoTransition = false;
      this.data.template.isPageSwitching = false;
      this.data.template.pageRowNumber = 10;
    }
  }

  /**
   * save
   * @returns
   */
  save(): void {
    if (this.data.template.isPageSwitching) {
      this.templateDetailForm.get('pageRowNumber').enable();
    } else {
      this.templateDetailForm.get('pageRowNumber').disable();
    }
    if (this.data.template.isAutoTransition) {
      this.templateDetailForm.get('transitionTime').enable();
    } else {
      this.templateDetailForm.get('transitionTime').disable();
    }
    this.submitted = true;
    if (this.templateDetailForm.valid) {
      if (
        this.data.isChangeTemplateType &&
        this.data?.isAreaEmergencyMessage &&
        this.data.template.templateType != TemplateTypeEnum.MAIN &&
        this.data.template.templateType != TemplateTypeEnum.EMERGENCY
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: this.translateService.instant('lcd-layout-editor.msg.change-template-type-error')
          }
        });
        return;
      }
      this.data.template.name = this.templateDetailForm.value.name;
      this.data.template.transitionTime = this.templateDetailForm.value.transitionTime;
      if (!this.data.template.isAutoTransition) {
        this.data.template.transitionTime = null;
        this.data.template.destination = null;
      }
      this.data.template.pageRowNumber = this.templateDetailForm.value.pageRowNumber;
      if (!this.data.template.pageRowNumber) {
        this.data.template.pageRowNumber = null;
      }
      // case edit template
      if (this.data.template?.id) {
        this.templateService.updateTemplate(this.data.template).subscribe(
          templateEdit => {
            this.dialogRef.close(templateEdit);
          },
          error => {
            this.showDialogError(error);
          }
        );
      } else {
        // case duplicate template
        if (this.data.title == this.translateService.instant('lcd-layout-editor.layout.edit-template')) {
          let template = this.changeDataOnClickEvent(this.data.template);
          template.modeActionTemplate = ModeActionTemplate.DUPLICATE;
          this.templateService.updateDetailedTemplate(Helper.convertDataTemplate(template)).subscribe(
            (data: Template) => {
              this.dialogRef.close(data);
            },
            error => {
              this.showDialogError(error);
            }
          );
        } else {
          // case add template or save-as template
          this.templateService.addTemplate(this.data.template).subscribe(
            templateNew => {
              this.dialogRef.close([templateNew, this.data.template]);
            },
            error => {
              this.showDialogError(error);
            }
          );
        }
      }
    }
  }

  /**
   * showDialogError
   * @param error
   */
  private showDialogError(error: any): void {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-error.error-network-api');
    } else if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.template-max'), `${this.NUMBER_TEMPLATE_MAX}`);
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
      this.isDuplicate = true;
      return;
    }
    this.isDuplicate = false;
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }
  /**
   * change data on-click event of area when change template type
   * @param templateChange
   * @returns
   */
  private changeDataOnClickEvent(templateChange: any): any {
    templateChange.layers.forEach(layer => {
      layer.areas.forEach(area => {
        area.getArea().isOnClickEvent = false;
        area.getArea().onClickEventDestination = null;
        area.getArea().onClickEventType = null;
      });
    });
    return templateChange;
  }

  /**
   * handling decimal
   * @param element
   */
  handlingDecimal(element: any): void {
    element.value = Helper.handlingDecimal(element.value);
  }

  /**
   * toggle multi timetable
   */
  toggleMultiTimetable() {
    this.data.template.isMultiTimetable = !this.data.template.isMultiTimetable;
  }

  /**
   * toggle page switching
   */
  togglePageSwitching() {
    this.data.template.isPageSwitching = !this.data.template.isPageSwitching;
    if (this.data.template.isPageSwitching) {
      this.templateDetailForm.get('pageRowNumber').enable();
      if (!this.data.template.pageRowNumber) {
        this.data.template.pageRowNumber = this.DEFAULT_PAGE_ROW_NUMBER;
      }
    } else {
      this.templateDetailForm.get('pageRowNumber').disable();
    }
    if (this.data.template.isAutoTransition && this.data.template.isPageSwitching) {
      this.data.template.isAutoTransition = false;
    }
  }
}
export interface DialogData {
  title: string;
  template: Template;
  templatesOfGroup: Array<Template>;
  templateGroup: TemplateGroup;
  isChangeTemplateType?: boolean;
  isAreaEmergencyMessage?: boolean;
  mode?: ModeActionTemplate;
}
