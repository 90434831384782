import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { DialogService } from 'app/service/dialog.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-reissue-receipt',
  templateUrl: './dialog-reissue-receipt.component.html',
  styleUrls: ['./dialog-reissue-receipt.component.scss']
})
export class DialogReissueReceiptComponent implements OnInit {
  currentLang: string;
  orderId: string;
  formReissueReceipt: FormGroup;
  Constant = Constant;
  mode: number = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogReissueReceiptComponent>,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private ticketManagerService: TicketManagerService,
    private dialogService: DialogService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.orderId = data.orderId;
    this.initFormSaleTicket();
  }

  ngOnInit(): void {
    if (this.data?.email) {
      this.formReissueReceipt.patchValue({
        emailAddress: this.data?.email
      });
    }
  }

  /**
   * initFormSaleTicket
   */
  initFormSaleTicket(): void {
    this.formReissueReceipt = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(254), Validators.pattern(Constant.REGEX_EMAIL_ADDRESS_REISSUE_RECEIPT)]]
    });
  }

  /**
   * get form Control
   */
  get f() {
    return this.formReissueReceipt.controls;
  }

  /**
   * confirm
   */
  confirm(): void {
    if (this.formReissueReceipt.valid) {
      this.mode = 3;
    }
  }

  /**
   * reissue
   */
  reissue(): void {
    const payload = {
      orderId: this.orderId,
      email: this.f?.emailAddress.value
    };
    this.ticketManagerService.resend(payload).subscribe(
      response => {
        if (response.result == 'succeeded') {
          this.dialogService.showDialog(
            DialogMessageComponent,
            {
              data: {
                title: this.translateService.instant('dialog-reissure-receipt.complete'),
                text: this.translateService.instant('dialog-reissure-receipt.msg-complete')
              }
            },
            result => {
              this.dialogRef.close();
            }
          );
        }
      },
      error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          },
          result => {
            this.dialogRef.close();
          }
        );
      }
    );
  }

  /**
   * cancel
   */
  cancel() {
    if (this.mode !== Constant.MODE_INFORMATION) {
      this.dialogRef.close();
    } else {
      this.mode = 1;
    }
  }
}
