import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-result-delete-reservation',
  templateUrl: './dialog-result-delete-reservation.component.html',
  styleUrls: ['./dialog-result-delete-reservation.component.scss']
})
export class DialogResultDeleteReservationComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogResultDeleteReservationComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  text: string;
  reservationId: string;
  languageKey: string;
}
