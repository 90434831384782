import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomTag } from '../../model/entity/custom-tag';
import { CustomTagService } from '../../service/custom-tag.service';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DialogEditGroupComponent } from '../dialog-edit-group/dialog-edit-group.component';
import { Constant } from 'app/config/constants';
import { Device } from 'app/model/entity/device';

@Component({
  selector: 'app-dialog-list-custom-tag',
  templateUrl: './dialog-list-custom-tag.component.html',
  styleUrls: ['./dialog-list-custom-tag.component.scss']
})
export class DialogListCustomTagComponent implements OnInit {
  /**
   * ElementRef input2
   */
  @ViewChild('input2') private elementRef: ElementRef;
  /**
   * index custom tag selected
   */
  indexCustomTagSelected: number;
  /**
   * list custom tag
   */
  listCustomTag: Array<CustomTag>;
  /**
   * list custom tag delete
   */
  listCustomTagDelete: Array<CustomTag> = new Array<CustomTag>();
  /**
   * custom tag selected
   */
  customTagSelected: CustomTag;

  /**
   * Constant
   */
  Constant = Constant;

  constructor(
    public dialogRef: MatDialogRef<DialogListCustomTagComponent>,
    private customTagService: CustomTagService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.fetchListCustomTag();
  }

  /**
   * fetch list custom tag
   */
  fetchListCustomTag() {
    this.customTagService.getCustomTag().subscribe(
      customTagDataList => {
        this.listCustomTag = new Array<CustomTag>();
        customTagDataList.forEach(customTagData => {
          this.listCustomTag.push(customTagData);
        });
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * delete custom tag
   */
  deleteCustomTag() {
    this.elementRef.nativeElement.focus();
    if (!this.customTagSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select Custom Tag.`
        }
      });
      return;
    }
    if (
      this.customTagSelected.name == Constant.SIGNAGE_GROUP ||
      this.customTagSelected.name == Constant.ANNOUNCE_GROUP ||
      this.customTagSelected.name == Constant.AREA_GROUP
    ) {
      return;
    }
    this.listCustomTagDelete.push(this.customTagSelected);
    this.listCustomTag = this.listCustomTag.filter(customTag => customTag.id != this.customTagSelected.id);
    this.customTagSelected = undefined;
  }

  /**
   * handle delete custom tag
   */
  saveDelete() {
    if (this.listCustomTagDelete.length == 0) {
      return;
    }
    this.listCustomTagDelete.forEach(customTag => {
      this.customTagService.deleteCustomTag(customTag).subscribe(
        () => {},
        error => {
          this.dialogRef.close();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    });
  }

  /**
   * edit custom tag
   */
  editCustomTag() {
    this.elementRef.nativeElement.focus();
    if (!this.customTagSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select Custom Tag.`
        }
      });
      return;
    }
    this.dialogService.showDialog(DialogEditGroupComponent, {
      data: {
        customTag: this.customTagSelected,
        listCustomTag: this.listCustomTag,
        devices: this.data.devices,
        accountId: this.data.accountId
      }
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  devices: Array<Device>;
  accountId: any;
}
