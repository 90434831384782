import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { Constant, DisplayModelEnum, LEDTemplateModeEnum } from 'app/config/constants';
import { TemplateGroupLED } from 'app/model/entity/destination/template-group-led';
import { ValidationFormService } from 'app/service/validation-form.service';

@Component({
  selector: 'dialog-template-group-led',
  templateUrl: './dialog-template-group-led.component.html',
  styleUrls: ['./dialog-template-group-led.component.scss']
})
export class DialogTemplateGroupLedComponent implements OnInit {
  /**
   * constant
   */
  Constant = Constant;
  /**
   * form group
   */
  templateGroupLedForm: FormGroup;
  /**
   * led template mode enum
   */
  LEDTemplateModeEnum = LEDTemplateModeEnum;
  /**
   * display mode enum
   */
  DisplayModelEnum = DisplayModelEnum;
  /**
   * template group led
   */
  templateGroup: TemplateGroupLED;
  /**
   * true if submitted
   */
  isSubmitted: boolean = false;

  /**
   * property name
   */
  propertyName: { [key: string]: string } = {
    name: 'Template group name',
    width: 'Template group width',
    height: 'Template group height'
  };

  constructor(
    public dialogRef: MatDialogRef<DialogTemplateGroupLedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService
  ) {}

  ngOnInit(): void {
    this.templateGroup = this.data.templateGroup;
    this.createForm();
  }

  /**
   * create form
   */
  private createForm() {
    this.templateGroupLedForm = this.formBuilder.group({
      name: [
        this.templateGroup.name,
        [
          this.validationFormService.invalidEmpty,
          this.validationFormService.maxLength(Constant.MAX_LENGTH_TEMPLATE_GROUP_NAME),
          this.validationFormService.existTemplateGroupName(this.data.templateGroups, this.templateGroup.templateMode)
        ]
      ],
      width: [
        this.templateGroup.width,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_TEMPLATE_WIDTH_SIZE),
          Validators.max(Constant.MAX_TEMPLATE_WIDTH_SIZE)
        ]
      ],
      height: [
        this.templateGroup.height,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_TEMPLATE_HEIGHT_SIZE),
          Validators.max(Constant.MAX_TEMPLATE_HEIGHT_SIZE)
        ]
      ],
      templateMode: [this.templateGroup.templateMode, []],
      displayModel: [this.templateGroup.displayModel, []]
    });
  }

  /**
   * save template mode
   */
  public save(): void {
    this.isSubmitted = true;
    if (this.templateGroupLedForm.valid) {
      var creationTime = this.templateGroup.id
        ? this.templateGroup.creationTime
        : formatDate(new Date(), Constant.FORMAT_DATE_TIME, Constant.LOCALE_CODE);
      let templateGroupNew = new TemplateGroupLED(
        this.templateGroup.id,
        this.templateGroupLedForm.value.name.trim(),
        this.templateGroupLedForm.value.width,
        this.templateGroupLedForm.value.height,
        creationTime,
        this.templateGroupLedForm.value.templateMode,
        this.templateGroupLedForm.value.displayModel
      );
      this.dialogRef.close(templateGroupNew);
    }
  }

  /**
   * handling Decimal
   * @param inputNumber
   * @param input
   */
  public handlingDecimal(inputNumber: number, input: any): void {
    input.value = Helper.handlingDecimal(inputNumber);
  }
}

export interface DialogData {
  title: string;
  templateGroup: TemplateGroupLED;
  templateGroups: Array<TemplateGroupLED>;
}
