import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { Color } from 'app/module/custom-component/color-picker/entity/color';
import { Helper } from 'app/common/helper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';

@Component({
  selector: 'app-info-text-highlighting',
  templateUrl: './info-text-highlighting.component.html',
  styleUrls: ['./info-text-highlighting.component.scss']
})
/**
 * Dialog Pick color
 */
export class InfoTextHighlightingSettingComponent implements OnInit {
  titleName = '';
  // data = {titleName: 1}
  @Input() data: any;
  @Input() listActive: any;
  @Input() lastRow: boolean;
  @Input() properties: any;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter(true);
  public isCustomHighlight: boolean;
  /**
   * language key
   */
  public languageKey: string;

  /**
   * commonObject
   */
  commonObject: Common;
  dataForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private scheduleMergeService: ScheduleMergeService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.isCustomHighlight = false;
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
      // multiple language column header
      this.multiLanguageReferenceRowAndColumn();
    });
  }
  /**
   * reference column
   */
  public referenceColumnArray: any[];
  ngOnInit() {
    this.dataForm = this.fb.group({
      titleName: [this.data.titleName],
      name: [this.data.name],
      object: [this.data.object],
      color: [this.data.color],
      opacity: [this.data.opacity],
      blinking: [this.data.blinking],
      isActive: [this.data.isActive],
      readOnlyName: [this.data.readOnlyName]
    });
    if (['highlight-1', 'highlight-2', 'highlight-3'].includes(this.data.titleName)) {
      this.isCustomHighlight = true;
    }
    let previousNameValue = this.dataForm.get('name').value;
    this.scheduleMergeService.actionUpdateColor //subscribe from change color by canvas
      .subscribe(data => {
        if (data.titleName == this.data.titleName && this.f.color != data.color) {
          this.dataForm.controls.color.setValue(data.color);
          const color = Color.fromHex(data.color);
          this.dataForm.controls.opacity.setValue(color.a);
          this.onUpdate.emit(this.f);
        }
      });
    this.dataForm.valueChanges.subscribe(value => {
      const nameValueChanged = value.name !== previousNameValue;
      if (nameValueChanged && ['highlight-1', 'highlight-2', 'highlight-3'].includes(value.titleName)) {
        return;
      }
      //if change data to change color in canvas
      if (value.titleName != 'highlight-setting') {
        this.onChangeData();
      } else {
        this.onUpdate.emit(this.f);
      }
    });
    this.multiLanguageReferenceRowAndColumn();
  }
  get f() {
    return this.dataForm.value;
  }
  onChangeData() {
    this.scheduleMergeService.updateColor(this.f);
    this.onUpdate.emit(this.f);
  }
  changeName() {
    this.onUpdate.emit(this.f);
  }

  /**
   * multi Language Reference Row And Column
   */
  private multiLanguageReferenceRowAndColumn(): void {
    this.referenceColumnArray = [];
    for (let i = 1; i < this.properties.length - 1; ++i) {
      if (i == 1) {
        this.referenceColumnArray.push(this.translateService.instant('dialog-duplicate-setting.time'));
      }
      this.referenceColumnArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.item`), `${i}`));
    }
  }
}
