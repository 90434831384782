import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { ALL_SOFTWARES, AutoLogoutTime, Constant, MODULE_NAME, MODULE_NAME_NEED_PROJECT } from 'app/config/constants';
import { LanguageHelper } from 'app/core/language/language.helper';
import { Common } from 'app/model/entity/common';
import { Privilege } from 'app/model/entity/privilege';
import { UserSetting } from 'app/model/entity/user-setting';
import { SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { FontService } from 'app/service/font.service';
import { UserSettingService } from 'app/service/user-setting.service';
import { UserService } from 'app/service/user.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { JhiLanguageService } from 'ng-jhipster';
import { ToastrService } from 'ngx-toastr';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
declare let FontFace: any;

@Component({
  selector: 'dialog-setting',
  templateUrl: './dialog-setting.component.html',
  styleUrls: ['./dialog-setting.component.scss']
})
export class DialogSettingComponent implements OnInit {
  Constant = Constant;
  /**
   * list fonts
   */
  public listFonts: Array<string> = new Array<string>();
  /**
   * list timezones
   */
  public listTimeZones: Array<Timezone> = new Array<Timezone>();
  /**
   * languages
   */
  public languages: any[];
  /**
   * user setting
   */
  public userSetting: UserSetting;
  /**
   * language selected
   */
  public languageSelected: string;
  /**
   * AutoLogoutTime
   */
  public AutoLogoutTime = AutoLogoutTime;
  /**
   * true if change timezone
   */
  private isChangeTimezone: boolean = false;
  /**
   * fonts added
   */
  private fontsAdded: any[] = [];
  /**
   * MODULE_NAME
   */
  public MODULE_NAME = MODULE_NAME;
  /**
   * list of available module name
   */
  public moduleNames: Array<string> = new Array<string>();
  /**
   * common object
   */
  private commonObject: Common;
  /**
   * languageTitle
   */
  public languageTitle: string;
  /**
   * languageKey
   */
  public languageKey: string;
  /**
   * languagesCode
   */
  public languagesCode: string[] = new Array();
  /**
   * languagesCodeClone
   */
  public languagesCodeClone: string[] = new Array(10);
  /**
   * languagesTranslation
   */
  public languagesTranslation = []; // List language for translation item

  constructor(
    private dialogRef: MatDialogRef<DialogSettingComponent>,
    private userSettingService: UserSettingService,
    private fontService: FontService,
    private dialogService: DialogService,
    public readonly store: Store<AppState>,
    private toast: ToastrService,
    private languageService: JhiLanguageService,
    private languageHelper: LanguageHelper,
    private dataService: DataService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private userService: UserService,
    private executingService: ExecutingService
  ) {
    this.commonObject = commonService.getCommonObject();
    this.languagesTranslation = Constant.LANGUAGES_SETTING;
    this.languageKey = this.commonObject.setting?.language;
    this.languagesCode = ['', '', '', '', '', '', '', '', '', ''];
    if (this.commonObject.setting?.languagesSetting) {
      this.languagesCode = this.commonObject.setting?.languagesSetting.includes('"')
        ? this.commonObject.setting?.languagesSetting.slice(1, -1).split(',')
        : this.commonObject.setting?.languagesSetting.split(',');
    }
  }

  async ngOnInit() {
    this.userSettingService.getAllTimeZone().subscribe(
      listTimeZone => {
        this.listTimeZones = listTimeZone;
        this.getUserSetting();
      },
      () => this.handleErrorFromApi()
    );
    this.languages = this.languageHelper.getAll();
    this.languageSelected = this.languageService.currentLang;
    this.getFonts();
    this.getModuleNames();
    this.executingService.executing();
    await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
    this.executingService.executed();
    this.languageTitle = this.translateService.instant('dialog-setting.configuration.language');
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        this.languageKey = this.commonObject.setting?.language;
        if (this.commonObject.setting?.languagesSetting) {
          this.languagesCode = this.commonObject.setting?.languagesSetting.includes('"')
            ? this.commonObject.setting?.languagesSetting.slice(1, -1).split(',')
            : this.commonObject.setting?.languagesSetting.split(',');
        }
      }
    });
  }

  /**
   * get fonts
   */
  private getFonts(): void {
    this.fontService.getAllFontName().subscribe(
      data => {
        this.listFonts = data;
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * import font
   */
  public importFont(): void {
    document.getElementById('importedFont').click();
  }

  /**
   * upload fonts
   * @param event
   * @returns
   */
  public uploadFonts(event: any): void {
    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    const isInvalidFile = [...selectedFiles].some(
      selectedFile =>
        !selectedFile.name.toLowerCase().endsWith(Constant.TTC_TYPE) && !selectedFile.name.toLowerCase().endsWith(Constant.TTF_TYPE)
    );
    if (isInvalidFile) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-setting.message.title-warning'),
          text: this.translateService.instant('dialog-setting.message.some-file-valid-type')
        }
      });
      selectedFiles = [...selectedFiles].filter(
        selectedFile =>
          selectedFile.name.toLowerCase().endsWith(Constant.TTC_TYPE) || selectedFile.name.toLowerCase().endsWith(Constant.TTF_TYPE)
      );
      if (selectedFiles.length <= 0) {
        return;
      }
    }
    this.fontService.uploadFile(selectedFiles).subscribe(
      data => {
        if (!data || data.length == 0) {
          return;
        }
        let commonObject = this.commonService.getCommonObject();
        commonObject.fonts = _.uniqWith(commonObject.fonts.concat(data), _.isEqual);
        this.store.dispatch(
          new SaveMainStateAction({
            common: commonObject
          })
        );
        let fontNames = data.map(font => font['name'].slice(0, font['name'].indexOf('.')));
        fontNames.forEach((fontName, index) => {
          if (this.listFonts.includes(fontName)) {
            data.splice(index, 1);
            return;
          }
          this.fontsAdded.push(data[index]);
          this.listFonts?.push(fontName);
        });
        this.handleLoadFonts(this.fontsAdded);
        event.target.files = null;
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * handle load fonts
   * @param fonts
   * @returns
   */
  private handleLoadFonts(fonts: any[]): void {
    if (!fonts || fonts.length == 0) {
      return;
    }
    let fontFaces = document.getElementById('font-collection');
    fonts.forEach(font => {
      fontFaces?.appendChild(
        document.createTextNode(
          `@font-face {font-family: ${font['name'].slice(0, font['name'].indexOf('.'))};src: url('${font['url']}') format('truetype');}`
        )
      );
    });
    document.head.appendChild(fontFaces);
    // load fonts
    Promise.all(
      fonts.map(font => {
        let fontFace = new FontFace(font['name'], `url(${font['url']})`);
        return new Promise(() => {
          fontFace.load();
        });
      })
    );
  }

  /**
   * get user setting
   */
  private getUserSetting(): void {
    let userId = this.commonService.getCommonObject().userId;
    if (userId) {
      this.userSettingService.getInfoSetting().subscribe(
        userSettingData => {
          this.userSetting = userSettingData;
          this.userSetting.timezone = this.listTimeZones.find(timezone => timezone.id == +userSettingData['timezone']);
        },
        () => this.handleErrorFromApi()
      );
    }
  }

  /**
   * save setting
   */
  public saveSetting(): void {
    this.userSetting.language = this.languageSelected;
    this.userSetting.languagesSetting = this.languagesCode.toString();
    this.userSettingService.saveInfoSetting(this.userSetting).subscribe(
      () => {
        let commonObject = this.commonService.getCommonObject();
        commonObject.setting = this.userSetting;
        this.store.dispatch(
          new SaveMainStateAction({
            common: commonObject
          })
        );
        // update language
        this.languageService.changeLanguage(this.languageSelected);
        this.toast.success(this.translateService.instant('common.save-success'), '');
        this.dialogRef.close();
        this.dataService.sendData([Constant.IS_CHANGE_TIME_ZONE, this.isChangeTimezone]);
        if (this.fontsAdded.length > 0) {
          this.dataService.sendData([Constant.IS_CHANGE_FONTS, this.fontsAdded]);
        }
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * change time zone
   * @param timezoneId
   */
  public changeTimezone(timezoneId: any): void {
    this.userSetting.timezone = this.listTimeZones.find(timezone => timezone.id == timezoneId);
    this.isChangeTimezone = true;
  }

  /**
   * change auto logout
   * @param autoLogoutTime
   */
  public changeAutoLogout(autoLogoutTime: any): void {
    this.userSetting.autoLogoutTime = autoLogoutTime;
  }

  /**
   * change start module
   * @param startupModule
   */
  public changeStartupModule(startupModule: any): void {
    this.userSetting.startupModule = startupModule;
  }

  /**
   * change language
   * @param languageKey
   */
  public changeLanguage(languageKey: string): void {
    this.languageSelected = languageKey;
  }

  /**
   * cancel
   */
  public cancel(): void {
    this.dialogRef.close();
    if (this.fontsAdded.length > 0) {
      this.dataService.sendData([Constant.IS_CHANGE_FONTS, this.fontsAdded]);
    }
  }

  /**
   * get list module name
   */
  private getModuleNames(): void {
    this.userService.getAllPrivileges().subscribe(
      (privilegesData: Privilege[]) => {
        if (!privilegesData.length) {
          return;
        }
        this.moduleNames = _.sortBy(privilegesData, ['id'])
          .map(privilege => privilege.moduleName)
          .filter(moduleName => !MODULE_NAME_NEED_PROJECT.includes(moduleName));
        this.moduleNames.sort(function(moduleName1, moduleName2) {
          return ALL_SOFTWARES.indexOf(moduleName1) - ALL_SOFTWARES.indexOf(moduleName2);
        });
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * getTitleLanguage
   * @param languageCode
   * @returns
   */
  public getTitleLanguage(languageCode: string): string {
    let language = this.Constant.LANGUAGES_SETTING.find(e => e.translation_language_code == languageCode);
    return this.languageKey == 'jp' ? language.language_name_ja : language.language_name_en;
  }

  /**
   * handle error from Api
   */
  private handleErrorFromApi(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.an-error')
      }
    });
  }
}

export class Timezone {
  id: number;
  offsetHour: number;
  offsetMinute: number;
  name: string;

  constructor(offsetHour: number, offsetMinute: number, name: string, id?: number) {
    this.offsetHour = offsetHour;
    this.offsetMinute = offsetMinute;
    this.name = name;
    this.id = id;
  }
}
