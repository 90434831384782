import { Component, OnInit } from '@angular/core';
import { Constant } from 'app/config/constants';
import { CommonTableService } from 'app/service/common-table.service';
import { Helper } from '../../common/helper';
import { TranslateService } from '@ngx-translate/core';
import { CommonTable } from 'app/model/entity/commonTable';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'dialog-specific-sequence-setting',
  templateUrl: './dialog-specific-sequence-setting.component.html',
  styleUrls: ['./dialog-specific-sequence-setting.component.scss']
})
export class DialogSpecificSequenceSettingComponent implements OnInit {
  constructor(
    private commonTableService: CommonTableService,
    public translateService: TranslateService,
    private dialogRef: MatDialogRef<DialogSpecificSequenceSettingComponent>
  ) {}
  Constant = Constant;

  /**
   * reference column
   */
  public referenceColumnArray: any[];
  private commonTableSave: CommonTable = new CommonTable();
  public specificSetting: any = { specific1: -1, specific2: -1, delayedPlaylist: -1, arrivalPlaylist: -1 };
  ngOnInit(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_SPECIFIC_SETTING).subscribe(data => {
      if (data) {
        this.specificSetting = JSON.parse(data.value);
      }
    });
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPPER_LIMIT).subscribe(data => {
      this.referenceColumnArray = [];
      let totalItem = data ? JSON.parse(data.value).item : Constant.ITEM_DEFAULT;
      for (let i = 1; i <= totalItem; ++i) {
        this.referenceColumnArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.item`), `${i}`));
      }
    });
  }
  public save(): void {
    this.commonTableSave.key = Constant.KEY_SPECIFIC_SETTING;
    this.commonTableSave.value = JSON.stringify(this.specificSetting);
    this.commonTableService.save(this.commonTableSave).subscribe(data => this.closeDialog());
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
