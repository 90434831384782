import { Component, OnInit, Inject } from '@angular/core';
import { Program } from 'app/model/entity/playlist';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';

@Component({
  selector: 'dialog-setting-program',
  templateUrl: './dialog-setting-program.component.html',
  styleUrls: ['./dialog-setting-program.component.scss']
})
export class DialogSettingProgramComponent implements OnInit {
  /**
   * list start times
   */
  listStartTime = [
    '00:00:00',
    '00:30:00',
    '01:00:00',
    '01:30:00',
    '02:00:00',
    '02:30:00',
    '03:00:00',
    '03:30:00',
    '04:00:00',
    '04:30:00',
    '05:00:00',
    '05:30:00',
    '06:00:00',
    '06:30:00',
    '07:00:00',
    '07:30:00',
    '08:00:00',
    '08:30:00',
    '09:00:00',
    '09:30:00',
    '10:00:00',
    '10:30:00',
    '11:00:00',
    '11:30:00',
    '12:00:00',
    '12:30:00',
    '13:00:00',
    '13:30:00',
    '14:00:00',
    '14:30:00',
    '15:00:00',
    '15:30:00',
    '16:00:00',
    '16:30:00',
    '17:00:00',
    '17:30:00',
    '18:00:00',
    '18:30:00',
    '19:00:00',
    '19:30:00',
    '20:00:00',
    '20:30:00',
    '21:00:00',
    '21:30:00',
    '22:00:00',
    '22:30:00',
    '23:00:00',
    '23:30:00'
  ];

  /**
   * list hour
   */
  listHour = [1, 2, 3, 4, 5, 6];
  /**
   * list minute
   */
  listMinute = [15, 30, 45, 60];
  /**
   * list second
   */
  listSecond = [15, 30, 45, 60];
  /**
   * list durations
   */
  listDurations: Array<number>;
  /**
   * start time
   */
  startTime: string;
  /**
   * unit time
   */
  unitTime: string;
  /**
   * duration program
   */
  durationProgram: number;
  /**
   * old start time program
   */
  oldStartTime: number;
  /**
   * old duration time program
   */
  oldDuration: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogSettingProgramComponent>,
    private dialogService: DialogService
  ) {
    this.startTime = this.convertIntToString(data.program.startTime);
    this.durationProgram = Math.round(data.program.duration * 100) / 100;
    this.unitTime = Constant.MINUTE;
    this.oldStartTime = this.data.program.startTime;
    this.oldDuration = Math.round(data.program.duration * 100) / 100;
  }

  ngOnInit(): void {
    this.listDurations = this.listMinute;
  }

  /**
   * change start time
   * @param e Event
   */
  changeStartTime(e) {
    (document.getElementById('start-time') as HTMLInputElement).style.background = '#e5e5e5';
    this.startTime = e.target.value;
  }

  /**
   * change duration
   * @param e Event
   */
  changeDuration(e) {
    (document.getElementById('duration') as HTMLInputElement).style.background = '#e5e5e5';
    this.durationProgram = e.target.value;
  }

  /**
   * input start time
   * @param e Event
   */
  inputStartTime(e) {
    let value = e.target.value;
    this.startTime = value;
    if (!value.match(Constant.REGEX_TIME)) {
      this.handleError('start-time');
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Incorrect format or invalid time.' } });
      return;
    }
  }

  /**
   * change unit time
   * @param e Event
   */
  changeUnitTime(e) {
    let value = e.target.value;
    this.unitTime = value;
    if (value == Constant.HOUR) {
      this.listDurations = this.listHour;
      this.durationProgram = 1;
    } else if (value == Constant.MINUTE) {
      this.listDurations = this.listMinute;
      this.durationProgram = 30;
    } else if (value == Constant.SECOND) {
      this.listDurations = this.listSecond;
      this.durationProgram = 30;
    }
  }

  /**
   * format time to hh:mm:ss
   * @param duration time
   */
  formatDuration(duration) {
    if (duration) {
      return new Date(duration * 1000).toISOString().substr(11, 8);
    }
  }

  /**
   * convert string hh:mm to int (minute)
   * @param value value hh:mm
   */
  convertStringToInt(value: string): number {
    let array = value.split(':');
    return +array[0] * 60 + +array[1] + +array[2] / 60;
  }

  /**
   * convert int (minute) to string hh:mm
   * @param value value duration of program
   */
  convertIntToString(value: number): string {
    value = value * 60;
    let hour = Math.floor(value / 3600);
    let minute = Math.floor((value - hour * 3600) / 60);
    let second = Math.round(value - hour * 3600 - minute * 60);
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0') + ':' + second.toString().padStart(2, '0');
  }

  /**
   * save data setting
   */
  save() {
    if (!this.startTime.match(Constant.REGEX_TIME)) {
      this.handleError('start-time');
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Incorrect format or invalid time.' } });
      return;
    }

    this.data.program.startTime = this.convertStringToInt(this.startTime);

    if (
      this.data.mediaList.findIndex(
        program => Helper.isOverlapBottom(this.data.program, program) || Helper.isOverlapTop(this.data.program, program)
      ) > -1
    ) {
      this.handleError('start-time');
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Invalid start time.' } });
      this.data.program.startTime = this.oldStartTime;
      return;
    }

    if (this.unitTime == Constant.HOUR) {
      this.data.program.duration = this.durationProgram * 60;
    } else if (this.unitTime == Constant.MINUTE) {
      this.data.program.duration = this.durationProgram;
    } else if (this.unitTime == Constant.SECOND) {
      this.data.program.duration = this.durationProgram / 60;
    }

    if (this.data.mediaList.findIndex(program => Helper.isOverlapBottom(this.data.program, program)) > -1) {
      this.handleError('duration');
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Invalid duration.' } });
      this.data.program.duration = this.oldDuration;
      return;
    }
    this.dialogRef.close(true);
  }

  /**
   * handle error
   */
  handleError(id: string) {
    let inputElement = document.getElementById(id) as HTMLInputElement;
    inputElement.focus();
    inputElement.style.background = '#f5aeae';
  }
}

export interface DialogData {
  program: Program;
  mediaList: Array<Program>;
}
