import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Constant } from 'app/config/constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonTable } from 'app/model/entity/commonTable';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Helper } from 'app/common/helper';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DialogService } from 'app/service/dialog.service';
import { PlayTimingSetting } from 'app/model/entity/play-timing-setting';
import { CommonService } from 'app/service/common.service';
import { CommonPlaylistRegistration } from 'app/model/entity/announcement/common-playlist-registratrion';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'dialog-play-timing-setting',
  templateUrl: './dialog-play-timing-setting.component.html',
  styleUrls: ['./dialog-play-timing-setting.component.scss']
})
export class DialogPlayTimingSettingComponent implements OnInit {
  readonly FORMAT_TIME = 'HH:mm';

  public PLAY_TIMING_1 = 'playTiming1';

  public PLAY_TIMING_2 = 'playTiming2';

  public PLAY_PERIOD = 'playPeriod';
  /**
   * config
   */
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };

  /**
   * time dateline
   */
  timeDateline: string;

  /**
   * language key
   */
  languageKey: string;

  /**
   * play timing setting
   */
  public playTimingSetting: PlayTimingSetting;

  /**
   * play timing setting clone deep
   */
  public playTimingSettingCloneDeep: PlayTimingSetting;

  /**
   * msg play timing 1
   */
  public msgPlayTiming1: any;

  /**
   * msg play timing 2
   */
  public msgPlayTiming2: any;

  /**
   * msg play cycle
   */
  public msgPlayPeriod: any;

  lastMinuteTo: number;
  lastHourTo: number;
  lastMinuteFrom: number;
  lastHourFrom: number;

  public isDuplicatePlayTiming: boolean = false;

  public isClear: boolean = false;

  /**
   * tab selected
   */
  public tabSelected: any;

  /**
   * list common playlist registration
   */
  public listCommonPlaylistRegistration: Array<CommonPlaylistRegistration> = new Array();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogPlayTiming,
    private dialogRef: MatDialogRef<DialogPlayTimingSettingComponent>,
    private commonTableService: CommonTableService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.tabSelected = this.data.tabSelected;
    this.listCommonPlaylistRegistration = this.data.saveListCommon;
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.timeDateline = Helper.convertFromMomentToTime(this.data.timeDateline);
    this.playTimingSetting = this.data.playTimingSetting
      ? JSON.parse(this.data.playTimingSetting.value)
      : new PlayTimingSetting(null, null, this.timeDateline, this.timeDateline, 10, false, false);
    this.playTimingSettingCloneDeep = _.cloneDeep(this.playTimingSetting);
    let timeTmpFrom = [];
    let timeTmpTo = [];
    timeTmpFrom = this.playTimingSetting.start.split(':');
    timeTmpTo = this.playTimingSetting.end.split(':');
    this.lastHourTo = timeTmpTo[0];
    this.lastMinuteTo = timeTmpTo[1];
    this.lastHourFrom = timeTmpFrom[0];
    this.lastMinuteFrom = timeTmpFrom[1];
    this.msgPlayTiming1 = <HTMLElement>document.getElementById(this.PLAY_TIMING_1);
    this.msgPlayTiming2 = <HTMLElement>document.getElementById(this.PLAY_TIMING_2);
    this.msgPlayPeriod = <HTMLElement>document.getElementById(this.PLAY_PERIOD);
  }

  /**
   * change time
   * @param data
   * @param type
   * @returns
   */
  public changeTime(data, type: any): void {
    if (this.isClear) {
      this.isClear = false;
      return;
    }
    if (typeof data == 'object') {
      return;
    }
    let lastHour, lastMinute;
    if (type == 'end') {
      lastHour = this.lastHourTo != undefined ? this.lastHourTo : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteTo != undefined ? this.lastMinuteTo : Constant.TMP_TIME_NULL;
    } else {
      lastHour = this.lastHourFrom != undefined ? this.lastHourFrom : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteFrom != undefined ? this.lastMinuteFrom : Constant.TMP_TIME_NULL;
    }
    let tmpTime;
    tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (currentHour != lastHour && currentMinute != lastMinute) {
      newHour = lastHour;
      newMinute = currentMinute;
      if (type == 'end') {
        this.playTimingSetting.end = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      } else {
        this.playTimingSetting.start = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      }
      this.cdr.detectChanges();
    } else {
      newHour = currentHour;
      newMinute = currentMinute;
    }
    if (type == 'end') {
      this.lastHourTo = newHour;
      this.lastMinuteTo = newMinute;
    } else {
      this.lastHourFrom = newHour;
      this.lastMinuteFrom = newMinute;
    }
  }

  /**
   * on submit
   */
  public onSubmit(): void {
    this.playTimingSetting.start = Helper.convertFromMomentToTime(this.playTimingSetting.start);
    this.playTimingSetting.end = Helper.convertFromMomentToTime(this.playTimingSetting.end);
    let playTimingSetting = new PlayTimingSetting(
      this.playTimingSetting.playbackTime1,
      this.playTimingSetting.playbackTime2,
      this.playTimingSetting.start,
      this.playTimingSetting.end,
      this.playTimingSetting.playPeriod,
      this.playTimingSetting.isPlayDelay,
      this.playTimingSetting.isPlayArrive
    );
    if (this.isInvaliDataBeforeSave(this.playTimingSetting)) {
      return;
    }
    let listCommon1 = this.listCommonPlaylistRegistration.map(common => common.commonAnnouncement1).filter(i => i);
    let listCommon2 = this.listCommonPlaylistRegistration.map(common => common.commonAnnouncement2).filter(i => i);
    let playlist = this.listCommonPlaylistRegistration.map(common => common.playlistSpecific).filter(i => i);
    if (
      (!this.playTimingSetting.playbackTime1 && listCommon1.length > 0 && this.playTimingSettingCloneDeep.playbackTime1 != null) ||
      (!this.playTimingSetting.playbackTime2 && listCommon2.length > 0 && this.playTimingSettingCloneDeep.playbackTime2 != null) ||
      (playlist.some(data => data && data.length > 0) &&
        ((!this.playTimingSetting.playbackTime1 && this.playTimingSettingCloneDeep.playbackTime1 != null) ||
          (!this.playTimingSetting.playbackTime2 && this.playTimingSettingCloneDeep.playbackTime2 != null)))
    ) {
      this.handleConfirmDialog(playTimingSetting);
    } else {
      let commonTable = new CommonTable(Constant.KEY_PLAY_TIMING_SETTING, JSON.stringify(playTimingSetting));
      this.dialogRef.close(commonTable);
    }
  }

  /**
   * is invalid data before save
   * @param playTimingSetting
   * @returns
   */
  public isInvaliDataBeforeSave(playTimingSetting: PlayTimingSetting): boolean {
    let isInvalid: boolean = false;
    let isInvalidPlayTime1: boolean = false;
    let isInvalidPlayTime2: boolean = false;
    let isInvalidPeriod: boolean = false;
    if (playTimingSetting.playbackTime1 && (+playTimingSetting.playbackTime1 > 10 || +playTimingSetting.playbackTime1 < 1)) {
      this.msgPlayTiming1.innerHTML = this.translateService.instant('dialog-play-timing-setting.msg.invalid-length-time');
      isInvalid = true;
      isInvalidPlayTime1 = true;
    }
    if (playTimingSetting.playbackTime2 && (+playTimingSetting.playbackTime2 > 10 || +playTimingSetting.playbackTime2 < 1)) {
      this.msgPlayTiming2.innerHTML = this.translateService.instant('dialog-play-timing-setting.msg.invalid-length-time');
      isInvalid = true;
      isInvalidPlayTime2 = true;
    }
    if (
      !isInvalidPlayTime1 &&
      !isInvalidPlayTime2 &&
      playTimingSetting.playbackTime1 &&
      playTimingSetting.playbackTime2 &&
      playTimingSetting.playbackTime1 == playTimingSetting.playbackTime2
    ) {
      this.msgPlayTiming1.innerHTML = this.translateService.instant('dialog-play-timing-setting.msg.duplicate-time');
      this.msgPlayTiming2.innerHTML = this.translateService.instant('dialog-play-timing-setting.msg.duplicate-time');
      isInvalid = true;
      isInvalidPlayTime1 = true;
      isInvalidPlayTime2 = true;
      this.isDuplicatePlayTiming = true;
    } else {
      this.isDuplicatePlayTiming = false;
    }
    if (!isInvalidPlayTime1) {
      this.msgPlayTiming1.innerHTML = '';
    }
    if (!isInvalidPlayTime2) {
      this.msgPlayTiming2.innerHTML = '';
    }
    if (
      !playTimingSetting.playPeriod ||
      (playTimingSetting.playPeriod && (+playTimingSetting.playPeriod > 60 || +playTimingSetting.playPeriod < 1))
    ) {
      this.msgPlayPeriod.innerHTML = this.translateService.instant('dialog-play-timing-setting.msg.invalid-length-cycle');
      isInvalid = true;
      isInvalidPeriod = true;
    }
    if (!isInvalidPeriod) {
      this.msgPlayPeriod.innerHTML = '';
    }
    if (this.isInvalidChangeDateLine(playTimingSetting.start, playTimingSetting.end)) {
      return true;
    }

    if (!isInvalidPeriod && this.isCycleLongerThanPlayTime(playTimingSetting.start, playTimingSetting.end, playTimingSetting.playPeriod)) {
      return true;
    }
    return isInvalid;
  }

  /**
   * is cycle longer than play time
   * @param start
   * @param end
   * @param cycle
   * @returns
   */
  public isCycleLongerThanPlayTime(start: any, end: any, cycle: Number): boolean {
    let startConvert = Helper.convertTime(start);
    let endConvert = Helper.convertTime(end);
    let changeDateLineConvert = Helper.convertTime(this.timeDateline);
    if (!this.checkConditionOfChangeDateLine(startConvert, endConvert, changeDateLineConvert)) {
      if (startConvert > endConvert) {
        endConvert += 24 * 3600;
      }
    }
    let duration = (endConvert - startConvert) / 60;
    if (duration != 0 && duration < +cycle) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-play-timing-setting.error'),
          text: this.translateService.instant('dialog-play-timing-setting.msg.cycle-greater-than-playback')
        }
      });
      return true;
    }
    return false;
  }

  /**
   * is invalid compare to change date line
   */
  public isInvalidChangeDateLine(start: any, end: any): boolean {
    let startConvert = Helper.convertTime(start);
    let endConvert = Helper.convertTime(end);
    let changeDateLineConvert = Helper.convertTime(this.timeDateline);
    if (this.checkConditionOfChangeDateLine(startConvert, endConvert, changeDateLineConvert)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-play-timing-setting.error'),
          text: this.translateService.instant('dialog-play-timing-setting.msg.start-end-compare-date-line')
        }
      });
      return true;
    }
    return false;
  }

  /**
   * check condition of change date line
   * @param startConvert
   * @param endConvert
   * @param changeDateLineConvert
   * @returns
   */
  public checkConditionOfChangeDateLine(startConvert: number, endConvert: number, changeDateLineConvert): boolean {
    if (
      (startConvert < changeDateLineConvert && changeDateLineConvert < endConvert) ||
      (changeDateLineConvert < endConvert && endConvert <= startConvert) ||
      (endConvert <= startConvert && startConvert < changeDateLineConvert)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * clear
   * @param type
   */
  public clear(type: any): void {
    let timeDatelineArray = this.timeDateline.split(':');
    if (type == 'from') {
      if (this.playTimingSetting.start == this.timeDateline) {
        return;
      }
      this.playTimingSetting.start = this.timeDateline;
      this.lastHourFrom = +timeDatelineArray[0];
      this.lastMinuteFrom = +timeDatelineArray[1];
    } else {
      if (this.playTimingSetting.end == this.timeDateline) {
        return;
      }
      this.playTimingSetting.end = this.timeDateline;
      this.lastHourTo = +timeDatelineArray[0];
      this.lastMinuteTo = +timeDatelineArray[1];
    }
    this.isClear = true;
    this.cdr.detectChanges();
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(undefined);
  }

  /**
   * change value
   * @param type
   * @param value
   */
  public changeValue(value, type): void {
    if (type == this.PLAY_TIMING_1) {
      let inputPlayTiming1 = <HTMLInputElement>document.getElementById('inputPlayTiming1');
      if (!value) {
        inputPlayTiming1.value = undefined;
        this.playTimingSetting.playbackTime1 = undefined;
      }
      if (this.isDuplicatePlayTiming) {
        this.msgPlayTiming2.innerHTML = '';
      }
      this.msgPlayTiming1.innerHTML = '';
    } else if (type == this.PLAY_TIMING_2) {
      let inputPlayTiming2 = <HTMLInputElement>document.getElementById('inputPlayTiming2');
      if (!value) {
        inputPlayTiming2.value = undefined;
        this.playTimingSetting.playbackTime2 = undefined;
      }
      if (this.isDuplicatePlayTiming) {
        this.msgPlayTiming1.innerHTML = '';
      }
      this.msgPlayTiming2.innerHTML = '';
    } else if (type == this.PLAY_PERIOD) {
      let inputPlayPeriod = <HTMLInputElement>document.getElementById('inputPlayPeriod');
      if (!value) {
        inputPlayPeriod.value = undefined;
        this.playTimingSetting.playPeriod = undefined;
      }
      this.msgPlayPeriod.innerHTML = '';
    }
  }

  /**
   * handle confirm dialog
   *
   * @param playTimingSetting
   */
  public handleConfirmDialog(playTimingSetting: PlayTimingSetting): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('announcement-manager.leave-play-time'),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      async result => {
        if (!result) {
          return;
        }
        let commonTable = new CommonTable(Constant.KEY_PLAY_TIMING_SETTING, JSON.stringify(playTimingSetting));
        this.dialogRef.close(commonTable);
      }
    );
  }

  /**
   * togglePlay
   * @param property
   */
  public togglePlay(property: string) {
    const clone = _.cloneDeep(this.playTimingSetting[property]);
    if (this.data.isConfirmTurnOff && this.playTimingSetting[property]) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('dialog-play-timing-setting.confirm-change-on'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        async result => {
          if (!result) {
            this.playTimingSetting[property] = clone;
            return;
          }
          this.playTimingSetting[property] = !clone;
        }
      );
    } else {
      this.playTimingSetting[property] = !this.playTimingSetting[property];
    }
  }
}

export interface DialogPlayTiming {
  playTimingSetting: any;
  timeDateline: any;
  tabSelected: any;
  saveListCommon: any;
  isConfirmTurnOff: boolean;
}
