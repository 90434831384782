import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { SettingType, TypeMediaFileEnum } from 'app/config/constants';
import { Folder } from 'app/model/entity/simple/folder';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { DialogService } from 'app/service/dialog.service';
import { SettingSignageChannelService } from 'app/service/setting-signage-channel.service';
import { SimpleFolderMediaService } from 'app/service/simple/simple-folder-media.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-setting-signage-display',
  templateUrl: './dialog-setting-signage-display.component.html',
  styleUrls: ['./dialog-setting-signage-display.component.scss']
})
export class DialogSettingSignageDisplayComponent implements OnInit {
  //#region constant
  public TypeMediaFileEnum = TypeMediaFileEnum;
  //#end region constant
  /**
   * videoElementRef
   */
  @ViewChild('video', { static: false })
  videoElementRef?: ElementRef;
  /**
   * list folder media
   */
  folders: Array<Folder> = new Array<Folder>();
  /**
   * folder is selected
   */
  folderSelected: Folder;
  /**
   * list media
   */
  medias: Array<SimpleMedia>;
  /**
   * media is selected
   */
  mediaSelected: SimpleMedia;
  /**
   * setting signage channel
   */
  settingSignageChannel: SettingSignageChannel;
  /**
   * true if open select box choose folder
   */
  isOpenSelectFolder: boolean;
  /**
   * true if open select box choose media
   */
  isOpenSelectMedia: boolean;
  constructor(
    private simpleMediaService: SimpleMediaService,
    private simpleFolderMediaService: SimpleFolderMediaService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogSettingSignageDisplayComponent>,
    private translateService: TranslateService,
    private settingSignageChannelService: SettingSignageChannelService
  ) {
    this.settingSignageChannel = data.settingSignageChannel;
  }

  ngOnInit(): void {
    this.simpleFolderMediaService.getAllFolderMedias().subscribe(
      simpleFolder => {
        this.folders = Helper.convertDataSimpleFolderMedias(simpleFolder);
        this.folderSelected = this.folders.find(folder => folder.id == this.settingSignageChannel.folderId);
        this.simpleMediaService.getMediasByFolderId(this.folderSelected?.id).subscribe(
          medias => {
            this.medias = Helper.convertDataSimpleMedias(medias, true);
            this.mediaSelected = this.medias.find(media => media.id == this.settingSignageChannel.mediaId);
          },
          () => this.handleError()
        );
      },
      () => this.handleError()
    );
  }

  /**
   * change folder media
   *
   * @param event
   */
  public changeFolderMedia(event: any): void {
    this.isOpenSelectFolder = !this.isOpenSelectFolder;
    const folderId = +event?.target?.value;
    if (this.folderSelected?.id == folderId) {
      return;
    }
    this.mediaSelected = undefined;
    this.folderSelected = folderId ? this.folders[this.folders.findIndex(folder => folder.id == folderId)] : undefined;
    this.simpleMediaService.getMediasByFolderId(this.folderSelected?.id).subscribe(
      medias => {
        this.medias = Helper.convertDataSimpleMedias(medias, true);
      },
      () => this.handleError()
    );
  }

  /**
   * change media
   *
   * @param event
   */
  public changeMedia(event: any): void {
    this.isOpenSelectMedia = !this.isOpenSelectMedia;
    const mediaId = +event.target.value;
    if (mediaId == -1) {
      this.mediaSelected = undefined;
      return;
    }
    const index = this.medias.findIndex(mediaData => mediaData?.id == mediaId);
    if (index == -1) {
      return;
    }
    this.mediaSelected = this.medias[index];
    if (this.mediaSelected.type == TypeMediaFileEnum.MP4) {
      this.videoElementRef?.nativeElement.load();
    }
  }

  /**
   * save
   */
  public save(): void {
    this.settingSignageChannel.folderId = this.mediaSelected ? this.folderSelected?.id : undefined;
    this.settingSignageChannel.mediaId = this.mediaSelected?.id;
    this.settingSignageChannel.type = this.data.type;
    // save data setting
    this.settingSignageChannelService.saveDataSetting(this.settingSignageChannel).subscribe(
      data => {
        this.dialogRef.close(<SettingSignageChannel>data);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle error
   *
   */
  private handleError(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }
}

export interface DialogData {
  settingSignageChannel: SettingSignageChannel;
  type: SettingType;
}
