import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-simple-signage-message',
  templateUrl: './dialog-simple-signage-message.component.html',
  styleUrls: ['./dialog-simple-signage-message.component.scss']
})
export class DialogSimpleSignageMessageComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  texts: [];
}
