import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomTag } from 'app/model/entity/custom-tag';
import { CustomTagService } from 'app/service/custom-tag.service';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { CustomTagElement } from 'app/model/entity/custom-tag-element';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { CommonService } from 'app/service/common.service';

@Component({
  selector: 'dialog-route-setting',
  templateUrl: './dialog-route-setting.component.html',
  styleUrls: ['./dialog-route-setting.component.scss']
})
export class DialogRouteSettingComponent implements OnInit {
  public customTagAreaGroup: CustomTag;
  public elements: Array<CustomTagElement> = new Array<CustomTagElement>();
  public routes: any = new Array<any>();

  private routesClone = new Array<any>();
  isDisableSave: boolean;
  languageKey: string;
  constructor(
    private customTagService: CustomTagService,
    public dialogRef: MatDialogRef<DialogRouteSettingComponent>,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
  }

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.customTagService.getCustomTag().subscribe(
      customTagDataList => {
        this.customTagAreaGroup = customTagDataList.find(data => data.name.includes('Area_Group'));
        this.elements = this.customTagAreaGroup.elements;
        const elementIds = this.elements.map(e => e.id);
        this.customTagService.getRouteByElements(elementIds).subscribe(routes => {
          this.routes = routes;
          this.routesClone = _.cloneDeep(this.routes);
          this.routes.forEach(route => {
            route.areaGroup = this.getAreaGroupName(route.elementId);
          });
        });
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  private getAreaGroupName(elementId) {
    return this.customTagAreaGroup.elements.find(e => e.id == elementId).name;
  }

  /**
   * addRoute
   * @param element
   */
  addRoute(element: CustomTagElement): void {
    if (this.validateHiddenAdd(element)) {
      return;
    }
    let route = { elementId: element.id, name: '' };
    this.routes.push(route);
  }

  /**
   * validateHiddenAdd
   * @param element
   * @returns
   */
  validateHiddenAdd(element): boolean {
    return this.routes.filter(e => e.elementId == element.id).length == 20;
  }

  getRoutes(elementId: number): any {
    return this.routes.filter(route => route.elementId == elementId);
  }

  save(): void {
    if (!this.routes || !this.routes.length) {
      return;
    }
    if (this.hasDuplicates()) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-route-setting.duplicate-route')
        }
      });
      return;
    }
    this.customTagService.saveRoutes(this.routes).subscribe(data => {
      this.dialogRef.close(data);
    });
  }

  /**
   * hasDuplicates
   * @returns
   */
  hasDuplicates(): boolean {
    const sortedItems = [...this.routes].sort((a, b) => {
      const nameComparison = a.name.localeCompare(b.name);
      return nameComparison !== 0 ? nameComparison : a.elementId - b.elementId;
    });

    const hasDuplicate = sortedItems.some(
      (item, index) => index > 0 && item.name === sortedItems[index - 1].name && item.elementId === sortedItems[index - 1].elementId
    );
    return hasDuplicate;
  }

  public close(): void {
    this.dialogRef.close();
  }

  changeChecked(route: any, e: any): void {
    e.stopPropagation();
    route.isCheck = !route.isCheck;
    this.isDisableSave = this.routes.some(route => route.isCheck);
  }

  isDisableButtonSave(): boolean {
    return !this.routes.every(route => route.name);
  }

  delete(): void {
    const idDeletes = this.routes.filter(route => route.isCheck).map(e => e.id);
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant(`dialog-route-setting.confirm-delete`),
          button1: this.translateService.instant('timetable-editor.yes'),
          button2: this.translateService.instant('timetable-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.customTagService.deleteRoutes(idDeletes).subscribe(() => {
          _.remove(this.routes, function(route) {
            return route.isCheck;
          });
          this.dialogRef.close(this.routes);
        });
      }
    );
  }

  /**
   * change Name
   * @param route
   */
  changeName(e: any, route: any) {
    const nameOld = this.routesClone.find(routeClone => routeClone.id == route.id).name;
    if (route.id && nameOld != e) {
      route.isEdit = true;
      route.nameOld = nameOld;
    } else {
      route.isEdit = false;
      delete route.nameOld;
    }
  }
}
