import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dialog-template-setting-schedule-registration',
  templateUrl: './dialog-template-setting-schedule-registration.component.html',
  styleUrls: ['./dialog-template-setting-schedule-registration.component.scss']
})
export class DialogTemplateSettingScheduleRegistrationComponent implements OnInit {
  /**
   * max display setting
   */
  public readonly MAX_DISPLAY_SETTING = 20;
  /**
   * displays
   */
  public displays = [
    { id: 1, name: 'AAAAAAAAA' },
    { id: 2, name: 'BBBBBBBBB' },
    { id: 3, name: 'CCCCCCCCC' },
    { id: 4, name: 'DDDDDDDDD' },
    { id: 5, name: 'EEEEEEEEE' },
    { id: 6, name: 'FFFFFFFFF' }
  ];
  /**
   * templates
   */
  templates = [
    { id: 1, name: 'AAAAAAAAA' },
    { id: 2, name: 'BBBBBBBBB' },
    { id: 3, name: 'CCCCCCCCC' },
    { id: 4, name: 'DDDDDDDDD' },
    { id: 5, name: 'EEEEEEEEE' },
    { id: 6, name: 'FFFFFFFFF' }
  ];
  constructor() {}

  ngOnInit(): void {}

  /**
   * add new row
   * @returns
   */
  public addRow(): void {
    if (this.displays.length == this.MAX_DISPLAY_SETTING) {
      return;
    }
    let display = { id: this.displays.length + 1, name: '' };
    this.displays.push(display);
  }
}
