import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, RoleIdEnum, RoleNameEnum } from 'app/config/constants';
import { UserService } from 'app/core/user/user.service';
import { CommonService } from 'app/service/common.service';
import { User } from '../../core/user/user.model';
import { DialogService } from '../../service/dialog.service';
import { DialogChangePasswordComponent } from '../dialog-change-password/dialog-change-password.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { UserService as UserServiceCMP } from '../../service/user.service';

@Component({
  selector: 'app-dialog-user-manager',
  templateUrl: './dialog-user-manager.component.html',
  styleUrls: ['./dialog-user-manager.component.scss']
})
/**
 * Component class for dialog user detail
 */
export class DialogUserManagerComponent implements OnInit {
  @ViewChild('userId') txtUserId: ElementRef;
  @ViewChild('userFullName') txtUserFullName: ElementRef;
  @ViewChild('password') txtPassword: ElementRef;
  @ViewChild('rePassword') txtConfirmPassword: ElementRef;
  /**
   * dialog title
   */
  title: string;
  /**
   * confirm password when changing
   */
  confirmedPassword: string = this.data.user.password ?? '';
  /**
   * is disable button change password
   */
  isDisableButtonChangePassword: boolean = true;
  /**
   * is visible show dialog
   */
  isVisibleDialog: boolean = true;
  //#region constant value validate
  private readonly USER_ID_MIN = 3;
  private readonly USER_ID_MAX = 10;
  private readonly USER_NAME_MIN = 1;
  private readonly USER_NAME_MAX = 16;
  private readonly PASSWORD_MIN = 8;
  private readonly PASSWORD_MAX = 16;
  private readonly MAX_USER = 100;
  private readonly IS_DUPLICATE = 'isDuplicate';
  //#endregion constant value validate

  /**
   * RoleIdEnum includes ADMINISTRATOR (1), MANAGER (2), USER  (3)
   */
  public RoleIdEnum = RoleIdEnum;

  constructor(
    private dialogRef: MatDialogRef<DialogUserManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private userService: UserService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private userServiceCMP: UserServiceCMP
  ) {}

  ngOnInit(): void {
    this.title = this.data.isEdit
      ? this.translateService.instant('dialog-user-manager.edit-user')
      : this.translateService.instant('dialog-user-manager.add-user');
    const userId = this.commonService.getCommonObject().userId;
    if (userId == this.data.user.id) {
      this.isDisableButtonChangePassword = false;
    }
  }

  /**
   * save current user
   */
  public saveUser(): void {
    let userId = this.data.user.userId.trim();
    if (userId.length == 0) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: this.translateService.instant('dialog-user-manager.msg.user-id-empty')
          }
        },
        () => {
          this.txtUserId.nativeElement.focus();
        }
      );
      return;
    }
    if (userId.length < this.USER_ID_MIN) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('dialog-user-manager.msg.user-id-min'),
              `${this.USER_ID_MIN}`,
              `${this.USER_ID_MAX}`
            )
          }
        },
        () => {
          this.txtUserId.nativeElement.focus();
        }
      );
      return;
    }
    if (userId.length > this.USER_ID_MAX) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('dialog-user-manager.msg.user-id-max'),
              `${this.USER_ID_MIN}`,
              `${this.USER_ID_MAX}`
            )
          }
        },
        () => {
          this.txtUserId.nativeElement.focus();
        }
      );
      return;
    }

    if (!userId.match(Constant.FORMAT_USER_ID_REGEX)) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('dialog-user-manager.msg.user-id-invalid'),
              `${this.USER_ID_MIN}`,
              `${this.USER_ID_MAX}`
            )
          }
        },
        () => {
          this.txtUserId.nativeElement.focus();
        }
      );
      return;
    }

    this.data.user.userId = userId;
    let userFullName = this.data.user.fullName;
    if (userFullName.trim().length < this.USER_NAME_MIN) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: this.translateService.instant('dialog-user-manager.msg.user-name-empty')
          }
        },
        () => {
          this.txtUserFullName.nativeElement.focus();
        }
      );
      return;
    }
    if (userFullName.length > this.USER_NAME_MAX) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-user-manager.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('dialog-user-manager.msg.user-name-max'), `${this.USER_NAME_MAX}`)
          }
        },
        () => {
          this.txtUserFullName.nativeElement.focus();
        }
      );
      return;
    }
    this.data.user.fullName = userFullName;
    if (!this.data.isEdit) {
      let password = this.data.user.password;
      if (password.length == 0) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-user-manager.msg.title-error'),
              text: this.translateService.instant('dialog-user-manager.msg.password-empty')
            }
          },
          () => {
            this.txtPassword.nativeElement.focus();
          }
        );
        return;
      }
      if (password.length < this.PASSWORD_MIN) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-user-manager.msg.title-error'),
              text: Helper.formatString(
                this.translateService.instant('dialog-user-manager.msg.password-min'),
                `${this.PASSWORD_MIN}`,
                `${this.PASSWORD_MAX}`
              )
            }
          },
          () => {
            this.txtPassword.nativeElement.focus();
          }
        );
        return;
      }
      if (password.length > this.PASSWORD_MAX) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-user-manager.msg.title-error'),
              text: Helper.formatString(
                this.translateService.instant('dialog-user-manager.msg.password-max'),
                `${this.PASSWORD_MIN}`,
                `${this.PASSWORD_MAX}`
              )
            }
          },
          () => {
            this.txtPassword.nativeElement.focus();
          }
        );
        return;
      }
      if (!password.match(Constant.FORMAT_PASSWORD_REGEX)) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-user-manager.msg.title-error'),
              text: this.translateService.instant('dialog-user-manager.msg.password-invalid')
            }
          },
          () => {
            this.txtPassword.nativeElement.focus();
          }
        );
        return;
      }
      if (password != this.confirmedPassword) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-user-manager.msg.title-error'),
              text: this.translateService.instant('dialog-user-manager.msg.password-not-match')
            }
          },
          () => {
            this.txtConfirmPassword.nativeElement.focus();
          }
        );
        return;
      }
      this.data.user.password = password;
    }
    this.data.user.role = this.getRoleNameByRoleId(this.data.user.roleId);
    this.userService.checkExistByUserId(this.data.user).subscribe(
      data => {
        if (data) {
          this.dialogService.showDialog(
            DialogMessageComponent,
            {
              data: {
                title: this.translateService.instant('dialog-user-manager.msg.title-error'),
                text: this.translateService.instant('dialog-user-manager.msg.duplicate-user')
              }
            },
            () => {
              this.txtUserId.nativeElement.focus();
            }
          );
          return;
        }
        this.data.user.email = `${userId}@lecip.com`;
        // edit
        if (this.data.isEdit) {
          this.userServiceCMP.updateUser(this.data.user).subscribe(
            user => {
              // close popup
              this.dialogRef.close({
                user: user
              });
            },
            error => {
              if (error.error.errorKey == Constant.ERROR_KEY_USER_NOT_EXISTS) {
                // close popup
                this.dialogRef.close({
                  user: this.data.user,
                  isDuplicateUserId: this.data.user.userId == this.data.userIdString ? this.IS_DUPLICATE : ''
                });
              } else {
                this.handleErrorSaveUser(error);
              }
            }
          );

          // add
        } else {
          this.userServiceCMP.addUser(this.data.user).subscribe(
            user => {
              // close popup
              this.dialogRef.close({
                user: user
              });
            },
            error => this.handleErrorSaveUser(error)
          );
        }
      },
      error => this.handleErrorSaveUser(error)
    );
  }

  /**
   * get role name by role id
   * @param roleId
   * @returns
   */
  private getRoleNameByRoleId(roleId: Number): string {
    switch (roleId) {
      case RoleIdEnum.ADMINISTRATOR:
        return RoleNameEnum.ADMINISTRATOR;
      case RoleIdEnum.MANAGER:
        return RoleNameEnum.MANAGER;
      case RoleIdEnum.USER:
        return RoleNameEnum.USER;
      default:
        return RoleNameEnum.USER;
    }
  }

  /**
   * re enter user's password
   * @param {Event} data input event containing confirmed password
   */
  public reEnterPassword(data: any): void {
    this.confirmedPassword = data.target.value;
  }

  /**
   * show dialog change password
   */
  public showPasswordChange(): void {
    if (this.isDisableButtonChangePassword) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-user-manager.msg.title-error'),
          text: this.translateService.instant('dialog-user-manager.msg.cannot-change-password')
        }
      });
      return;
    }
    this.dialogService.showDialog(DialogChangePasswordComponent, {}, () => {
      this.isVisibleDialog = true;
    });
    this.isVisibleDialog = false;
  }
  /**
   * handle Error Save Timetable
   */
  private handleErrorSaveUser(error: any): void {
    let msg = this.translateService.instant('dialog-message.an-error');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = Helper.formatString(this.translateService.instant('user-manager.msg.max-user'), `${this.MAX_USER}`);
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME_USER) {
      msg = this.translateService.instant('dialog-user-manager.msg.duplicate-user');
    } else if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-user-manager.msg.error-network');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('user-manager.msg.title-error'),
        text: msg
      }
    });
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  isEdit: boolean;
  user: User;
  userIdString: string;
}
