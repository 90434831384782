import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonTable } from 'app/model/entity/commonTable';
import { Constant } from 'app/config/constants';
import { CommonTableService } from 'app/service/common-table.service';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'dialog-simple-sync-setting',
  templateUrl: './dialog-simple-sync-setting.component.html',
  styleUrls: ['./dialog-simple-sync-setting.component.scss']
})
export class DialogSimpleSyncSettingComponent implements OnInit {
  /**
   * common table
   */
  private commonTable: CommonTable;
  /**
   * simple sync setting
   */
  public isSimpleSyncSetting: boolean;

  constructor(
    public dialogRef: MatDialogRef<CommonTable>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonTableService: CommonTableService
  ) {}

  ngOnInit(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_SIMPLE_SYNC_SETTING).subscribe(
      data => {
        if (data) {
          this.commonTable = data;
          this.isSimpleSyncSetting = JSON.parse(data.value);
        } else {
          this.commonTable = new CommonTable(Constant.KEY_SIMPLE_SYNC_SETTING);
          this.isSimpleSyncSetting = false;
        }
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * toggle change simple sync setting
   *
   * @returns
   */
  public toggleChangeSimpleSyncSetting(): void {
    this.isSimpleSyncSetting = !this.isSimpleSyncSetting;
  }

  /**
   * save simple sync setting
   *
   * @returns
   */
  public save(): void {
    this.commonTable.value = JSON.stringify(this.isSimpleSyncSetting);
    this.commonTableService.save(this.commonTable).subscribe(
      () => {
        this.dialogRef.close();
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }
}

/**
 * interface DialogData
 */
export interface DialogData {}
