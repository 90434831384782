import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, ScreenNameEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { Label } from '../../model/entity/label';
import { DialogService } from '../../service/dialog.service';
import { LabelService } from '../../service/label.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-route-label-manager',
  templateUrl: './dialog-route-label-manager.component.html',
  styleUrls: ['./dialog-route-label-manager.component.scss']
})
export class DialogRouteLabelManagerComponent implements OnInit {
  /**
   * ElementRef btn delete
   */
  @ViewChild('btnDelete') private elementRefBtnDelete: ElementRef;
  /**
   * ElementRef btn add
   */
  @ViewChild('btnAdd') private elementRefBtnAdd: ElementRef;
  /**
   * ElementRef btn rename
   */
  @ViewChild('btnRename') private elementRefBtnRename: ElementRef;
  /**
   * string
   */
  title: string;
  /**
   * label list
   */
  labelList: Array<Label> = new Array<Label>();
  /**
   * label is selected
   */
  labelSelected: Label;
  /**
   * true if click add or rename label
   */
  isAddLabel: boolean;
  /**
   * constant
   */
  readonly MAX_LENGTH_LABEL_NAME = 16;

  /**
   * function id of the screen
   */
  functionId: number;

  /**
   * constructor
   * @param labelService LabelService
   * @param dialogService DialogService
   * @param dialogRef
   */
  constructor(
    private labelService: LabelService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DialogRouteLabelManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService
  ) {
    this.title = data.title;
    this.functionId = data.functionId;
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.getAllLabelRoute();
  }

  /**
   * get all label
   */
  private getAllLabelRoute(): void {
    this.labelService.getLabels(this.functionId).subscribe(
      labels => {
        if (labels.length == 0) {
          return;
        }
        this.labelList = labels;
        this.selectLabel(this.labelList[0]);
      },
      error => {
        this.dialogRef.close();
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * add label
   */
  public addLabel(): void {
    if (this.labelSelected && this.labelSelected.isEdit) {
      return;
    }
    this.elementRefBtnAdd.nativeElement.blur();
    this.labelList.push(new Label(''));
    this.labelList[this.labelList.length - 1].isEdit = true;
    this.isAddLabel = false;
    this.selectLabel(this.labelList[this.labelList.length - 1]);
    this.isAddLabel = true;
  }

  /**
   * save label
   */
  public saveLabel(): void {
    let labelName = this.labelSelected.name;
    // validate min length
    if (labelName.trim().length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-route-label-manager.label-empty')
        }
      });
      return;
    }
    // validate max length
    if (labelName.length > this.MAX_LENGTH_LABEL_NAME) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('dialog-route-label-manager.label-max-length'),
            `${this.MAX_LENGTH_LABEL_NAME}`
          )
        }
      });
      return;
    }
    // validate duplicate label
    this.labelService.checkExistLabelName(this.functionId, this.labelSelected.id ?? null, labelName).subscribe(
      data => {
        if (data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-route-label-manager.duplicate-label')
            }
          });
          return;
        }
        // save label
        this.labelSelected.name = labelName;
        this.labelSelected.isEdit = false;
        if (this.labelSelected.id) {
          this.labelService.updateLabel(this.labelSelected, this.functionId).subscribe(
            label => {
              let selectedIndex = this.labelList.findIndex(label => label.id == this.labelSelected.id);
              if (selectedIndex == -1) {
                return;
              }
              this.labelList[selectedIndex] = label;
              this.selectLabel(this.labelList[selectedIndex]);
            },
            error => {
              this.dialogRef.close();
              this.handleErrorSaveLabel(error);
            }
          );
        } else {
          this.labelService.addLabel(this.labelSelected, this.functionId).subscribe(
            label => {
              this.labelList[this.labelList.length - 1] = label;
              this.selectLabel(this.labelList[this.labelList.length - 1]);
            },
            error => {
              this.dialogRef.close();
              this.handleErrorSaveLabel(error);
            }
          );
        }
        this.isAddLabel = false;
      },
      error => this.handleErrorSaveLabel(error)
    );
  }

  /**
   * select label
   * @param label label is selected when click on screen
   */
  public selectLabel(label: Label): void {
    if (this.isAddLabel || !label) {
      return;
    }
    this.labelSelected = label;
  }

  /**
   * rename label
   */
  public renameLabel(): void {
    if (this.isAddLabel || !this.labelSelected) {
      return;
    }
    this.elementRefBtnRename.nativeElement.blur();
    this.labelSelected.isEdit = true;
    this.isAddLabel = true;
  }

  /**
   * delete label
   */
  public deleteLabel(): void {
    if (!this.labelSelected) {
      return;
    }
    this.elementRefBtnDelete.nativeElement.blur();
    if (!this.labelSelected.id) {
      this.labelList.pop();
      this.isAddLabel = false;
      if (this.labelList.length > 0) {
        this.selectLabel(this.labelList[0]);
      } else {
        this.labelSelected = undefined;
      }
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            title: this.translateService.instant('dialog-route-label-manager.title'),
            text: Helper.formatString(this.translateService.instant('dialog-route-label-manager.delete-label'), this.labelSelected.name),
            button1: this.translateService.instant('dialog-route-label-manager.yes'),
            button2: this.translateService.instant('dialog-route-label-manager.btn-no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          // confirm the label exists
          this.labelService.checkExistLabelById(this.labelSelected.id, this.functionId).subscribe(
            data => {
              if (!data) {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: Helper.formatString(
                      this.translateService.instant('dialog-route-label-manager.label-not-exit'),
                      `${this.labelSelected.name}`
                    )
                  }
                });
                return;
              }
              this.labelService.deleteLabel(this.labelSelected).subscribe(
                () => {
                  let labelIndex = this.labelList.findIndex(label => label.id === this.labelSelected.id);
                  if (labelIndex == -1) {
                    return;
                  }
                  this.labelList.splice(labelIndex, 1);
                  this.isAddLabel = false;
                  if (this.labelList.length > 0) {
                    this.selectLabel(this.labelList[0]);
                  } else {
                    this.labelSelected = undefined;
                  }
                },
                error => {
                  this.dialogRef.close();
                  Helper.handleError(error, this.translateService, this.dialogService);
                }
              );
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      );
    }
  }

  /**
   *
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  public keyDown(e): void {
    if (e.keyCode == 27 && !this.isAddLabel) {
      this.dialogRef.close(this.labelList);
    }
  }

  /**
   * close popup
   */
  public closePopup(): void {
    this.dialogRef.close(this.labelList?.filter(label => label.id));
  }

  /**
   * handle error
   * @param error
   * @returns
   *
   */
  private handleErrorSaveLabel(error: any): void {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
      msg = this.translateService.instant('dialog-route-label-manager.duplicate-label');
    } else if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-route-label-manager.error-network');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }
}

export interface DialogData {
  functionId: number;
  title: string;
  screen: string;
}
