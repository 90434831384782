import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { CalendarInformation } from 'app/model/entity/calendar-information';
import { ContentDay } from 'app/model/entity/content-day';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { Timetable } from 'app/model/entity/timetable';
import { DialogService } from 'app/service/dialog.service';
import { TimetableContentDayService } from 'app/service/timetable-content-day.service';
import { TimetableService } from 'app/service/timetable.service';
import _ from 'lodash';
import moment from 'moment';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { CommonService } from 'app/service/common.service';

@Component({
  selector: 'dialog-reference-setting',
  templateUrl: './dialog-reference-setting.component.html',
  styleUrls: ['./dialog-reference-setting.component.scss']
})
export class DialogReferenceSettingComponent implements OnInit {
  /**
   * constants
   */
  readonly NAME_NOT_EXIST = '<Unknown>';
  readonly OLD_ID = 'oldId';
  readonly OLD_NO = 'oldNo';
  readonly OLD_SUFFIX = 'oldSuffix';
  readonly OLD_NAME = 'oldName';
  readonly IS_CHANGE_NO = 'isChangeNo';
  readonly IS_CHANGE_SUFFIX = 'isChangeSuffix';
  readonly IS_CHANGE_NAME = 'isChangeName';
  readonly FORMAT_DATE = 'YYYY-MM-DD';

  /**
   * start date
   */
  startDate: Date;
  /**
   * finish date
   */
  finishDate: Date;
  /**
   * list no
   */
  noList: Set<string>;
  /**
   * list suffix
   */
  suffixList: Set<string>;
  /**
   * all timetables
   */
  timetables: Array<Timetable>;
  /**
   * timetables original
   */
  timetablesOriginal: Array<Timetable>;
  /**
   * timetables reference
   */
  timetablesReference: Array<Timetable>;
  /**
   * device id selected
   */
  deviceIdSelected: Number = -1;
  /**
   * current date
   */
  currentDate: Date;
  /**
   * date end
   */
  dateEnd: Date;

  constructor(
    public dialogRef: MatDialogRef<DialogReferenceSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private timetableContentDayService: TimetableContentDayService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private timetableService: TimetableService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getAllTimetables();
  }

  /**
   * get all timetables
   */
  private getAllTimetables(): void {
    this.timetableService.getAllTimetables().subscribe(
      data => {
        this.timetables = data;
        this.noList = new Set(this.timetables.map(timetable => timetable.no));
        this.suffixList = new Set(this.timetables.map(tt => tt.suffix));
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * set date
   * @param isChooseStart
   * @param event
   */
  public setDate(isChooseStart: boolean, event): void {
    if (isChooseStart) {
      this.startDate = new Date(event);
    } else {
      this.finishDate = new Date(event);
    }
    this.getDateToValidate();
    // if invalid date => return
    if (!this.startDate || !this.finishDate || this.startDate < this.currentDate || this.finishDate > this.dateEnd) {
      return;
    }
    // get data timetables
    this.timetableContentDayService
      .getTimetablesBetweenDates(new CalendarInformation(this.data.deviceCalendar.id, this.startDate, this.finishDate))
      .subscribe(
        data => {
          this.timetablesOriginal = data;
          this.timetablesReference = _.cloneDeep(this.timetablesOriginal);
          this.timetablesReference.forEach(timetable => {
            timetable[this.OLD_ID] = timetable.id;
            timetable[this.OLD_NO] = timetable.no;
            timetable[this.OLD_SUFFIX] = timetable.suffix;
            timetable[this.OLD_NAME] = timetable.name;
          });
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
  }

  /**
   * change no
   * @param index
   * @param no
   */
  public changeNo(index: number, no: string): void {
    let timetable = this.timetablesReference[index];
    timetable.no = no;
    timetable[this.IS_CHANGE_NO] = timetable[this.OLD_NO] !== no;
    this.updateNameForTimetable(timetable);
  }

  /**
   * change suffix
   * @param index
   * @param suffix
   */
  public changeSuffix(index: number, suffix: string): void {
    let timetable = this.timetablesReference[index];
    timetable.suffix = suffix;
    timetable[this.IS_CHANGE_SUFFIX] = timetable[this.OLD_SUFFIX] !== suffix;
    this.updateNameForTimetable(timetable);
  }

  /**
   * update name for timetable
   * @param timetable
   */
  private updateNameForTimetable(timetable: Timetable) {
    const index = this.timetables.findIndex(tt => tt.no == timetable.no && tt.suffix == timetable.suffix);
    if (index != -1) {
      timetable.id = this.timetables[index].id;
      timetable.name = this.timetables[index].name;
    } else {
      timetable.name = this.NAME_NOT_EXIST;
    }
    timetable[this.IS_CHANGE_NAME] = timetable[this.OLD_NAME] !== timetable.name;
  }

  /**
   * save
   * @returns
   */
  public save(): void {
    // validate replacement
    if (this.deviceIdSelected == -1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-reference-setting.choose-replacement')
        }
      });
      return;
    }

    // validate period
    if (!this.validateDate()) {
      return;
    }

    // save data for device reference
    const calendarInfoReference = new CalendarInformation(this.deviceIdSelected, this.startDate, this.finishDate);
    this.timetableContentDayService.getContentDaysBetweenDates(calendarInfoReference).subscribe(
      data => {
        if (data.length) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant('dialog-reference-setting.overwrite'),
                button1: this.translateService.instant('dialog-reference-setting.yes'),
                button2: this.translateService.instant('dialog-reference-setting.btn-no')
              }
            },
            result => {
              if (!result) {
                return;
              }
              this.saveContentDays();
            }
          );
        } else {
          this.saveContentDays();
        }
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * save content days
   */
  private saveContentDays(): void {
    const calendarInfo = new CalendarInformation(this.data.deviceCalendar.id, this.startDate, this.finishDate);
    this.timetableContentDayService.getContentDaysBetweenDates(calendarInfo).subscribe(
      contentDays => {
        let contentDaysNew = new Array<ContentDay>();
        // get list timetable id unknown
        const timetableIdsUnknown = this.timetablesReference
          .filter(timetable => timetable.name === this.NAME_NOT_EXIST)
          .map(tt => tt[this.OLD_ID]);
        // get data timetable to asign data for device reference
        contentDays.forEach(contentDay => {
          if (timetableIdsUnknown.includes(contentDay.timetableId)) {
            return;
          }
          const index = this.timetablesReference.findIndex(timetable => timetable[this.OLD_ID] == contentDay.timetableId);
          if (index != -1) {
            contentDay.timetableId = this.timetablesReference[index].id;
          }
          contentDaysNew.push(contentDay);
        });

        // save data content day for device reference
        this.timetableContentDayService
          .saveContentDaysCopiedOfDevice(
            contentDaysNew,
            this.deviceIdSelected,
            moment(this.startDate).format(this.FORMAT_DATE),
            moment(this.finishDate).format(this.FORMAT_DATE)
          )
          .subscribe(
            data => {
              this.dialogRef.close();
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * validate start date, finish date
   * @returns
   */
  private validateDate(): boolean {
    // start date empty
    if (!this.startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-reference-setting.start-invalid')
        },
        disableClose: true
      });
      return false;
    }
    // finish date empty
    if (!this.finishDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-reference-setting.finish-invalid')
        },
        disableClose: true
      });
      return false;
    }
    this.getDateToValidate();
    // start date invalid
    if (this.startDate < this.currentDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-reference-setting.start-invalid')
        },
        disableClose: true
      });
      return false;
    }
    // finish date invalid
    if (this.finishDate > this.dateEnd || this.finishDate < this.startDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-reference-setting.finish-invalid')
        },
        disableClose: true
      });
      return false;
    }
    return true;
  }

  /**
   * get date to validate
   */
  private getDateToValidate(): void {
    const date = Helper.getCurrentByTimezoneSetting(this.commonService.getCommonObject(), false);
    this.currentDate = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
    date.setFullYear(date.getFullYear() + Constant.MAX_YEAR);
    date.setDate(date.getDate() - 1);
    this.dateEnd = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
}

/**
 * interface DialogData
 */
export interface DialogData {
  /**
   * device calendar
   */
  deviceCalendar: DeviceCalendar;
  /**
   * list device calendar
   */
  deviceCalendars: Array<DeviceCalendar>;
}
