import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleOperationManagerService } from 'app/service/schedule-operation-maganer.service';

@Component({
  selector: 'app-dialog-show-hidden-item-setting',
  templateUrl: './dialog-show-hidden-item-setting.component.html',
  styleUrls: ['./dialog-show-hidden-item-setting.component.scss']
})
/**
 * Dialog Pick color
 */
export class DialogShowHiddenItemSettingSettingComponent implements OnInit {
  listData: any[] = [];
  dataApi: any[];
  dataTopApi: any[];
  headerLabelsFromAPI: any[];
  listDataTop: any[];
  constructor(
    private scheduleOperationManagerService: ScheduleOperationManagerService,
    public dialogRef: MatDialogRef<DialogShowHiddenItemSettingSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.initData();
  }

  /**
   * checkDataS3WithDB
   * @returns
   */
  checkDataS3WithDB() {
    const headers = [];
    const headerRows = this.data.schedulesDaily[0] ? JSON.parse(JSON.stringify(this.data.schedulesDaily[0])) : [];
    const listReject = [
      'calculatedTime',
      'hold',
      'arrive',
      'priority',
      'suspension',
      'suspention',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditArrive',
      'isEditPriority',
      'isEditSuspension'
    ];
    let index = 0;
    if (!this.data || !this.data.schedulesDaily || !this.data.schedulesDaily[0]) {
      return;
    }
    for (const header in headerRows) {
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          let label = header;
          if (this.headerLabelsFromAPI) {
            label = this.headerLabelsFromAPI[headers.length];
          }
          headers.push({ label: label, field: header, isActive: true, sortOrder: index++ });
          for (const dataTmpApi of this.dataApi) {
            if (dataTmpApi.field == header && dataTmpApi.label == label) {
              headers[headers.length - 1].checkApi = true;
              headers[headers.length - 1].sortOrder = dataTmpApi.sortOrder;
              headers[headers.length - 1].isActive = dataTmpApi.isActive;
            }
          }
        }
      }
    }
    let checkApi = true;
    if (this.dataApi.length != headers.length) {
      checkApi = false;
    }
    for (const header of headers) {
      if (!header.checkApi) {
        //convert sort order. call api save item;
        checkApi = false;
      }
    }
    if (!checkApi) {
      for (const header of headers) {
        header.isActive = true;
      }
      this.onSaveFirst(headers);
    }
    if (checkApi) {
      headers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
    }
    this.listData = headers;
  }

  /**
   * initData
   */
  initData() {
    this.headerLabelsFromAPI = this.scheduleOperationManagerService.listScheduleOperationFromAPI?.headers
      ? JSON.parse(this.scheduleOperationManagerService.listScheduleOperationFromAPI.headers)
      : null;
    this.scheduleOperationManagerService.getShowHiddenItem(this.data.areaName).subscribe(
      e => {
        if (e && e.headers && e.headerTops) {
          this.dataApi = e.headers;
          this.listDataTop = e.headerTops;
          this.checkDataS3WithDB();
        } else {
          this.addHeader();
        }
      },
      err => {
        this.addHeader();
      }
    );
  }

  /**
   * drop
   * @param event
   * @returns
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex == 0) {
      return;
    }
    moveItemInArray(this.listData, event.previousIndex, event.currentIndex);
  }

  /**
   * onSaveFirst
   * @param headers
   */
  onSaveFirst(headers) {
    for (const [index, value] of headers.entries()) {
      value.sortOrder = index;
      value.isActive = true;
      delete value.checkApi;
    }
    for (const [index, value] of this.listDataTop.entries()) {
      value.sortOrder = index;
      value.isActive = true;
      delete value.checkApi;
    }
    const dataSave = {
      headers: headers,
      headerTops: this.listDataTop
    };
    this.scheduleOperationManagerService.saveShowHiddenItem(JSON.stringify(dataSave), this.data.areaName).subscribe(e => {});
  }

  /**
   * onSave
   */
  onSave() {
    for (const [index, value] of this.listData.entries()) {
      value.sortOrder = index;
      delete value.checkApi;
    }
    for (const [index, value] of this.listDataTop.entries()) {
      value.sortOrder = index;
      delete value.checkApi;
    }
    const dataSave = {
      headers: this.listData,
      headerTops: this.listDataTop
    };
    this.scheduleOperationManagerService.headerScheduleOperations = this.listData;
    //save data to database data change.
    this.scheduleOperationManagerService.saveShowHiddenItem(JSON.stringify(dataSave), this.data.areaName).subscribe(e => {
      this.dialogRef.close(this.listData);
    });
  }

  /**
   * addHeader
   * @returns
   */
  addHeader() {
    const headers = [];
    this.listDataTop = [
      { label: { en: 'Temporary suspension', ja: '臨時運休' }, field: 'suspension', isActive: true },
      { label: { en: 'Delay', ja: '遅延' }, field: 'hold', isActive: true },
      { label: { en: 'Arrival', ja: '到着' }, field: 'arrive', isActive: true },
      { label: { en: 'Priority', ja: '優先' }, field: 'priority', isActive: true }
    ];
    const listReject = [
      'calculatedTime',
      'hold',
      'arrive',
      'priority',
      'suspension',
      'color',
      'index',
      'key',
      'itemEdit',
      'checkTime',
      'notIn35Min',
      'blink',
      'isDelete',
      'isDeleteRowInsert',
      'isEdit',
      'isEditHold',
      'isEditArrive',
      'isEditPriority',
      'isEditSuspension'
    ];
    let index = 0;
    if (!this.data || !this.data.schedulesDaily || !this.data.schedulesDaily[0]) {
      return;
    }
    const headerRows = JSON.parse(JSON.stringify(this.data.schedulesDaily[0]));
    for (const header in headerRows) {
      const re = listReject.findIndex(e => e == header);
      if (re == -1) {
        const i = headers.findIndex(e => e.field == header);
        if (i == -1) {
          let label = header;
          if (this.headerLabelsFromAPI) {
            label = this.headerLabelsFromAPI[headers.length];
          }
          headers.push({ label: label, field: header, isActive: true, sortOrder: index++ });
        }
      }
    }
    this.listData = headers;
    this.onSaveFirst(headers);
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  schedulesDaily: [
    {
      title: string;
      text: string;
      texts: Array<string>;
    }
  ];
  languageKey: string;
  areaName: string;
}
