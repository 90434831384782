import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { DialogConfirmWebRefundTicketComponent } from '../dialog-confirm-web-refund-ticket/dialog-confirm-web-refund-ticket.component';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'dialog-web-refund-ticket',
  templateUrl: './dialog-web-refund-ticket.component.html',
  styleUrls: ['./dialog-web-refund-ticket.component.scss']
})
export class DialogWebRefundTicketComponent implements OnInit {
  currentLang: string;
  listOrderId: string;
  salesAmount: number;
  refundFee: number;
  cancelFee: number;
  validateMessage: {
    refundFee: string;
    cancelFee: string;
    refundAmount: string;
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogWebRefundTicketComponent>,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.listOrderId = data.listOrderId;
    this.salesAmount = data.salesAmount;
    if (data.refundFee) {
      this.refundFee = data.refundFee;
    }
    this.validateMessage = {
      refundFee: '',
      cancelFee: '',
      refundAmount: ''
    };
  }

  ngOnInit(): void {}

  /**
   * validateNumber
   * @param data
   * @returns
   */
  validateNumber(data: any): boolean {
    const pattern = /^[0-9]\d*$/;
    return pattern.test(data);
  }

  /**
   * validateInput
   * @param prop
   */
  validateInput(prop: string) {
    switch (prop) {
      case 'refundFee':
        if (!this.refundFee) {
          this.validateMessage.refundAmount = '';
          this.validateMessage.refundFee = this.translateService.instant('dialog-web-refund-ticket.is-empty');
        } else if (!this.validateNumber(this.refundFee) || +this.refundFee >= +this.salesAmount) {
          this.validateMessage.refundAmount = '';
          this.validateMessage.refundFee = Helper.formatString(
            this.translateService.instant('dialog-web-refund-ticket.is-number'),
            `${+this.salesAmount - 1}`
          );
        } else {
          this.validateMessage.refundFee = '';
        }
        break;
      case 'cancelFee':
        if (!this.cancelFee) {
          this.validateMessage.refundAmount = '';
          this.validateMessage.cancelFee = this.translateService.instant('dialog-web-refund-ticket.is-empty');
        } else if (!this.validateNumber(this.cancelFee) || +this.cancelFee >= +this.salesAmount) {
          this.validateMessage.refundAmount = '';
          this.validateMessage.cancelFee = Helper.formatString(
            this.translateService.instant('dialog-web-refund-ticket.is-number'),
            `${+this.salesAmount - 1}`
          );
        } else {
          this.validateMessage.cancelFee = '';
        }
        break;
      default:
        break;
    }
  }

  /**
   * refund
   */
  confirm() {
    if (this.validateBeforeSave()) {
      return;
    }
    const refundAmount = this.refundFee ? this.salesAmount - this.refundFee - this.cancelFee : this.salesAmount;
    if (refundAmount <= 0) {
      this.validateMessage.refundAmount = this.translateService.instant('dialog-web-refund-ticket.error-refund-amount');
      return;
    } else {
      this.validateMessage.refundAmount = '';
    }
    this.dialogService.showDialog(
      DialogConfirmWebRefundTicketComponent,
      {
        data: {
          listOrderId: this.listOrderId,
          salesAmount: this.salesAmount,
          refundFee: this.refundFee,
          refundAmount: refundAmount,
          cancelFee: this.cancelFee
        }
      },
      result => {
        if (result && result == 'close') {
          return;
        }
        this.dialogRef.close(result);
      }
    );
  }

  /**
   * validateBeforeSave
   * @returns
   */
  validateBeforeSave(): boolean {
    let flag = false;

    //refundFee
    if (!this.refundFee) {
      this.validateMessage.refundAmount = '';
      this.validateMessage.refundFee = this.translateService.instant('dialog-web-refund-ticket.is-empty');
      flag = true;
    } else if (!this.validateNumber(this.refundFee) || +this.refundFee >= +this.salesAmount) {
      this.validateMessage.refundAmount = '';
      this.validateMessage.refundFee = Helper.formatString(
        this.translateService.instant('dialog-web-refund-ticket.is-number'),
        `${+this.salesAmount - 1}`
      );
      flag = true;
    } else {
      this.validateMessage.refundFee = '';
    }

    //cancelFee
    if (!this.cancelFee) {
      this.validateMessage.refundAmount = '';
      this.validateMessage.cancelFee = this.translateService.instant('dialog-web-refund-ticket.is-empty');
      flag = true;
    } else if (!this.validateNumber(this.cancelFee) || +this.cancelFee >= +this.salesAmount) {
      this.validateMessage.refundAmount = '';
      this.validateMessage.cancelFee = Helper.formatString(
        this.translateService.instant('dialog-web-refund-ticket.is-number'),
        `${+this.salesAmount - 1}`
      );
      flag = true;
    } else {
      this.validateMessage.cancelFee = '';
    }

    return flag;
  }
}
