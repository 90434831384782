import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { OperationInformationSetting } from 'app/model/entity/operation-information-setting';
import { DialogService } from 'app/service/dialog.service';
import { OperationInformationSettingService } from 'app/service/operation-info-setting.service';
import _ from 'lodash';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'dialog-operation-information-setting',
  templateUrl: './dialog-operation-information-setting-schedule.component.html',
  styleUrls: ['./dialog-operation-information-setting-schedule.component.scss']
})
export class DialogOperationInformationSettingScheduleComponent implements OnInit {
  /**
   * constants
   */
  public readonly MIN_TIME_OPERATION = 0;
  public readonly MAX_TIME_OPERATION = 120;
  public readonly MAX_LENGTH_TEXT = 16;
  /**
   * ElementRef table operation
   */
  @ViewChild('tableOperation') tableOperationElement: ElementRef;
  /**
   * ElementRef input text
   */
  @ViewChild('textOperation') textOperationElement: ElementRef;
  /**
   * ElementRef input time
   */
  @ViewChild('timeOperation') timeOperationElement: ElementRef;
  /**
   * ElementRef btn delete
   */
  @ViewChild('btnDeleteOperation') private elementRefBtnDelete: ElementRef;
  /**
   * ElementRef btn add
   */
  @ViewChild('btnAddOperation') private elementRefBtnAdd: ElementRef;
  /**
   * ElementRef btn rename
   */
  @ViewChild('btnRenameOperation') private elementRefBtnRename: ElementRef;
  /**
   * operation information list
   */
  operationInfos: Array<OperationInformationSetting>;
  /**
   * new operation information list
   */
  newOperationInfos: Array<OperationInformationSetting>;
  /**
   * operation selected
   */
  operationSelected: OperationInformationSetting;
  /**
   * true if dragging
   */
  isShowPlaceholder: boolean;

  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DialogOperationInformationSettingScheduleComponent>,
    private translateService: TranslateService,
    private operationInfoService: OperationInformationSettingService
  ) {}

  ngOnInit(): void {
    this.getAllOperationInfos();
  }

  /**
   * get all operation infos
   */
  private getAllOperationInfos(): void {
    this.operationInfoService.getOperationInfosSchedule().subscribe(data => {
      this.operationInfos = data;
      this.newOperationInfos = _.cloneDeep(this.operationInfos);
      this.selectOperation(this.operationInfos[0]);
    });
  }

  /**
   * add operation
   */
  public addOperation(): void {
    if (this.operationSelected && this.operationSelected.isEdit) {
      return;
    }
    this.elementRefBtnAdd.nativeElement.blur();
    let maxIndex = this.operationInfos
      ?.map(operation => operation.index)
      .reduce(function(a, b) {
        return Math.max(a, b);
      }, 0);
    this.operationInfos.push(new OperationInformationSetting('', 0, maxIndex + 1));
    this.operationInfos[this.operationInfos.length - 1].isEdit = true;
    this.selectOperation(this.operationInfos[this.operationInfos.length - 1]);
  }

  /**
   * select operation
   * @param operation
   */
  public selectOperation(operation: OperationInformationSetting): void {
    if (this.operationSelected?.isEdit) {
      return;
    }
    this.operationSelected = operation;
  }

  /**
   * rename operation
   */
  public renameOperation(): void {
    if (!this.operationSelected || this.operationSelected.isEdit) {
      return;
    }
    this.elementRefBtnRename.nativeElement.blur();
    this.operationSelected.isEdit = true;
  }

  /**
   * delete operation
   */
  public deleteOperation(): void {
    if (!this.operationSelected) {
      return;
    }
    this.elementRefBtnDelete.nativeElement.blur();
    if (!this.operationSelected.id) {
      this.operationInfos.pop();
      this.operationSelected = this.operationInfos[0];
      this.scrollTop();
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            title: this.translateService.instant('dialog-operation-information-setting.confirm'),
            text: Helper.formatString(
              this.translateService.instant('dialog-operation-information-setting.delete-operation'),
              this.operationSelected.text
            ),
            button1: this.translateService.instant('dialog-operation-information-setting.yes'),
            button2: this.translateService.instant('dialog-operation-information-setting.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.operationInfoService.deleteOperationInfoSchedule(this.operationSelected.id).subscribe(
            isExist => {
              if (!isExist) {
                this.dialogService.showDialog(
                  DialogMessageComponent,
                  {
                    data: {
                      title: this.translateService.instant('dialog-error.title'),
                      text: Helper.formatString(
                        this.translateService.instant('dialog-operation-information-setting.text-not-exist'),
                        `${this.operationSelected.text}`
                      )
                    }
                  },
                  () => {
                    this.dialogRef.close();
                  }
                );
                return;
              }
              const index = this.operationInfos.findIndex(operation => operation.id === this.operationSelected.id);
              if (index == -1) {
                return;
              }
              this.operationInfos.splice(index, 1);
              this.newOperationInfos = _.cloneDeep(this.operationInfos);
              this.operationSelected = this.operationInfos[0];
              this.scrollTop();
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      );
    }
  }

  /**
   * scroll top
   */
  private scrollTop(): void {
    const element = _.get(this.tableOperationElement, 'nativeElement.children[0]', this.tableOperationElement);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * save operation information
   */
  public saveOperation(): void {
    // validate
    if (!this.validateBeforeSave()) {
      return;
    }

    // validate duplicate text
    this.operationInfoService.checkExistTextSchedule(this.operationSelected.id ?? null, this.operationSelected.text).subscribe(
      isExist => {
        if (isExist) {
          this.textOperationElement.nativeElement.focus();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-operation-information-setting.duplicate-text')
            },
            disableClose: true
          });
          return;
        }

        // save data
        this.operationInfoService.saveOperationInfoSchedule(this.operationSelected).subscribe(
          data => {
            this.operationSelected.isEdit = false;
            // case: edit data
            if (this.operationSelected.id) {
              const index = this.operationInfos.findIndex(operation => operation.id == this.operationSelected.id);
              if (index == -1) {
                return;
              }
              this.operationInfos[index] = data;
              this.newOperationInfos = _.cloneDeep(this.operationInfos);
              this.selectOperation(this.operationInfos[index]);
            } else {
              // case: add data
              this.operationInfos[this.operationInfos.length - 1] = data;
              this.newOperationInfos = _.cloneDeep(this.operationInfos);
              this.selectOperation(this.operationInfos[this.operationInfos.length - 1]);
            }
          },
          error => this.handleError(error)
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * validate before save
   * @returns
   */
  private validateBeforeSave(): boolean {
    // validate text
    let text = this.operationSelected.text;
    // validate text min length
    if (!text.trim().length) {
      this.textOperationElement.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-operation-information-setting.text-empty')
        }
      });
      return false;
    }
    // validate text max length
    if (text.length > this.MAX_LENGTH_TEXT) {
      this.textOperationElement.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('dialog-operation-information-setting.text-max-length'),
            `${this.MAX_LENGTH_TEXT}`
          )
        }
      });
      return false;
    }

    // validate time
    if (this.operationSelected.time == null) {
      this.timeOperationElement.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-operation-information-setting.time-empty')
        }
      });
      return false;
    }

    // validate time
    if (this.operationSelected.time > this.MAX_TIME_OPERATION) {
      this.timeOperationElement.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('dialog-operation-information-setting.time-max-length'),
            `${this.MAX_TIME_OPERATION}`
          )
        }
      });
      return false;
    }

    return true;
  }

  /**
   * Drop operation info
   * @param event CdkDragDrop
   */
  public dropOperationInfo(event: CdkDragDrop<any[]>): void {
    if (this.operationSelected?.isEdit) {
      return;
    }
    let currentOperationInfos = event.container.data;
    moveItemInArray(currentOperationInfos, event.previousIndex, event.currentIndex);
    currentOperationInfos.forEach((operation, index) => (operation.index = index));
    this.operationInfoService.saveOperationInfosSchedule(currentOperationInfos).toPromise();
  }

  /**
   * key down
   * @param event
   */
  @HostListener('document:keydown', ['$event'])
  public keyDown(event): void {
    if (event.keyCode == 27) {
      this.dialogRef.close(this.newOperationInfos);
    }
  }

  /**
   * close popup
   */
  public closePopup(): void {
    this.dialogRef.close(this.newOperationInfos);
  }

  /**
   *     handle error
   * @param error
   */
  private handleError(error: any) {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-error.error-network-api');
    } else if (error?.error?.detail == Constant.ERROR_EXISTS_NAME) {
      msg = this.translateService.instant('dialog-operation-information-setting.duplicate-text');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }
}
