import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Constant } from 'app/config/constants';
import { Helper } from 'app/common/helper';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'dialog-playback-time',
  templateUrl: './dialog-playback-time.component.html',
  styleUrls: ['./dialog-playback-time.component.scss']
})
export class DialogPlaybackTimeComponent implements OnInit {
  readonly FORMAT_TIME = 'HH:mm';
  /**
   * config
   */
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };

  /**
   * time from
   */
  public timeFrom: any;

  /**
   * time to
   */
  public timeTo: any;

  lastMinuteTo: number;
  lastHourTo: number;
  lastMinuteFrom: number;
  lastHourFrom: number;

  public isClear: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDisplayTimeData,
    private dialogRef: MatDialogRef<DialogPlaybackTimeComponent>,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.timeFrom = this.data.playTimeFrom;
    this.timeTo = this.data.playTimeTo;
    let timeTmpFrom = [];
    let timeTmpTo = [];
    timeTmpFrom = this.timeFrom.split(':');
    timeTmpTo = this.timeTo.split(':');
    this.lastHourTo = timeTmpTo[0];
    this.lastMinuteTo = timeTmpTo[1];
    this.lastHourFrom = timeTmpFrom[0];
    this.lastMinuteFrom = timeTmpFrom[1];
  }

  /**
   * setting
   */
  public setting(): void {
    this.timeFrom = Helper.convertFromMomentToTime(this.timeFrom);
    this.timeTo = Helper.convertFromMomentToTime(this.timeTo);
    if (this.checkValidatePlaybackTimeOfSequence(this.timeFrom, this.timeTo)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-playback-time.error'),
          text: this.translateService.instant('dialog-playback-time.invalid-playback-time')
        }
      });
      return;
    }
    if (this.timeFrom == Constant.TMP_TIME_NULL && this.timeTo == Constant.TMP_TIME_NULL) {
      this.timeFrom = null;
      this.timeTo = null;
    }
    this.dialogRef.close([this.timeFrom, this.timeTo]);
  }

  /**
   * check validate playback time of sequence
   * @param media
   * @returns
   */
  private checkValidatePlaybackTimeOfSequence(timeFrom: string, timeTo: string): boolean {
    let valueFrom = Helper.convertTime(timeFrom);
    let valueTo = Helper.convertTime(timeTo);
    if (timeFrom == null || timeTo == null) {
      return;
    }
    if (valueFrom < 0) {
      return true;
    }
    if (valueFrom > 24 * 60 * 3600 || valueTo > 24 * 60 * 3600) {
      return true;
    }
    if (valueFrom > valueTo && valueTo != 0) {
      return true;
    }
    if (valueTo == valueFrom && valueTo != 0 && valueFrom != 0) {
      return true;
    }
  }

  /**
   * change time
   * @param data
   * @param type
   */
  public changeTime(data, type: any): void {
    if (this.isClear) {
      this.isClear = false;
      return;
    }
    if (typeof data == 'object') {
      return;
    }
    let lastHour, lastMinute;
    if (type == 'to') {
      lastHour = this.lastHourTo != undefined ? this.lastHourTo : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteTo != undefined ? this.lastMinuteTo : Constant.TMP_TIME_NULL;
    } else {
      lastHour = this.lastHourFrom != undefined ? this.lastHourFrom : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteFrom != undefined ? this.lastMinuteFrom : Constant.TMP_TIME_NULL;
    }
    let tmpTime;
    tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (currentHour != lastHour && currentMinute != lastMinute) {
      newHour = lastHour;
      newMinute = currentMinute;
      if (type == 'to') {
        this.timeTo = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      } else {
        this.timeFrom = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      }
      this.cdr.detectChanges();
    } else {
      newHour = currentHour;
      newMinute = currentMinute;
    }
    if (type == 'to') {
      this.lastHourTo = newHour;
      this.lastMinuteTo = newMinute;
    } else {
      this.lastHourFrom = newHour;
      this.lastMinuteFrom = newMinute;
    }
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

  public clear(type: any): void {
    if (type == 'from') {
      if (this.timeFrom == Constant.TMP_TIME_NULL) {
        return;
      }
      this.timeFrom = Constant.TMP_TIME_NULL;
      this.lastHourFrom = 0;
      this.lastMinuteFrom = 0;
    } else {
      if (this.timeTo == Constant.TMP_TIME_NULL) {
        return;
      }
      this.timeTo = Constant.TMP_TIME_NULL;
      this.lastHourTo = 0;
      this.lastMinuteTo = 0;
    }
    this.isClear = true;
    this.cdr.detectChanges();
  }
}

export interface DialogDisplayTimeData {
  playTimeFrom: string;
  playTimeTo: string;
  isOpenDialogDisplayTime: boolean;
}
