<div id="myModal" class="modal modal-template-group-detail show"> <div class="modal-dialog modal-dialog-centered"> <div class="modal-content"> <form [formGroup]="templateGroupLedForm"> <div class="modal-header clearfix"> <h4 class="modal-title pull-left">{{ data.title }}</h4> </div> <div class="modal-body"> <div class="form-group clearfix"> <label>Name</label> <div class="input-item"> <input type="text" class="form-control" placeholder="Template group name" formControlName="name" autofocus/> <div class="error-msg" *ngIf="templateGroupLedForm.get('name').hasError('invalidEmpty') && isSubmitted"> Template group name cannot be empty. </div> <div class="error-msg" *ngIf="templateGroupLedForm.get('name').hasError('existName') && isSubmitted"> Template group name already exists. </div> <div class="error-msg" *ngIf="templateGroupLedForm.get('name').hasError('maxlength') && isSubmitted"> Template group name must contain no more than 16 characters. </div> </div> </div> <div class="form-group clearfix"> <label>Width</label> <div class="input-item" [ngClass]="{ disabled: data.templateGroup.id }"> <input type="number" class="form-control" placeholder="Width" formControlName="width" min="{{ Constant.MIN_TEMPLATE_WIDTH_SIZE }}" max="{{ Constant.MAX_TEMPLATE_WIDTH_SIZE }}" autofocus [readOnly]="data.templateGroup.id" onkeypress="return event.charCode >= 48 && event.charCode <= 57" (ngModelChange)="handlingDecimal(inputWidth.value, inputWidth)" #inputWidth blockCopyPaste/> <div class="error-msg" *ngIf="
                  (templateGroupLedForm.get('width').hasError('invalidEmpty') ||
                    templateGroupLedForm.get('width').hasError('min') ||
                    templateGroupLedForm.get('width').hasError('max')) &&
                  isSubmitted
                "> Please enter width between 1~240. </div> </div> </div> <div class="form-group clearfix"> <label>Height</label> <div class="input-item" [ngClass]="{ disabled: data.templateGroup.id }"> <input type="number" class="form-control" placeholder="Height" formControlName="height" min="{{ Constant.MIN_TEMPLATE_HEIGHT_SIZE }}" max="{{ Constant.MAX_TEMPLATE_HEIGHT_SIZE }}" autofocus [readOnly]="data.templateGroup.id" onkeypress="return event.charCode >= 48 && event.charCode <= 57" (ngModelChange)="handlingDecimal(inputHeight.value, inputHeight)" #inputHeight blockCopyPaste/> <div class="error-msg" *ngIf="
                  (templateGroupLedForm.get('height').hasError('invalidEmpty') ||
                    templateGroupLedForm.get('height').hasError('min') ||
                    templateGroupLedForm.get('height').hasError('max')) &&
                  isSubmitted
                "> Please enter height between 1~40. </div> </div> </div> <div class="form-group clearfix" style="display: flex;"> <label style="width: 155px;">Template Mode</label> <div [ngClass]="{ disabled: data.templateGroup.id }"> <label style="width: fit-content;" class="radio"> <input type="radio" name="templateMode" [value]="LEDTemplateModeEnum.DESTINATION_SIGN" formControlName="templateMode"/> <span>Destination Sign</span> </label> <label style="width: fit-content;" class="radio disabled"> <input type="radio" name="templateMode" [value]="LEDTemplateModeEnum.ON_BUS_DISPLAY" formControlName="templateMode"/> <span>Bus Information Display</span> </label> <label style="width: fit-content;" class="radio disabled"> <input type="radio" name="templateMode" [value]="LEDTemplateModeEnum.SIGNAGE_LED" formControlName="templateMode"/> <span>Signage LED</span> </label> </div> </div> <div class="form-group clearfix" style="display: flex;"> <label style="width: 155px;">Display Model</label> <div [ngClass]="{ disabled: data.templateGroup.id }"> <label style="width: fit-content;" class="radio"> <input type="radio" name="displayModel" [value]="DisplayModelEnum.FULL_COLOR" formControlName="displayModel"/> <span>Full Color</span> </label> <label style="width: fit-content;" class="radio"> <input type="radio" name="displayModel" [value]="DisplayModelEnum.AMBER" formControlName="displayModel"/> <span>Amber</span> </label> <label style="width: fit-content;" class="radio"> <input type="radio" name="displayModel" [value]="DisplayModelEnum.WHITE" formControlName="displayModel"/> <span>White</span> </label> </div> </div> </div> <div class="modal-footer"> <button type="submit" class="btn btn-primary btn-success" (click)="save()">Save</button> <button type="button" class="btn btn-primary" mat-dialog-close>Cancel</button> </div> </form> </div> </div> </div> 